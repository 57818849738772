"use client";
import { motion } from "framer-motion";
import { fadeIn } from "../utils/animation";

const ExploreCard = ({ id, imgUrl, title, index, active, handleClick }) => (
    <motion.div
        variants={fadeIn("right", "spring", index * 0.5, 0.75)}
        className={`relative ${active === id ? "lg:flex-[3.5] flex-[10]" : "lg:flex-[1.0] flex-[5]"
            } flex items-center justify-center  w-full md:w-[128px] md:h-[360px] transition-[flex] duration-[0.7s] ease-out-flex cursor-pointer`}
        onMouseEnter={() => handleClick(id)}
    >
        <img
            src={imgUrl}
            alt={title}
            className="absolute w-full h-full object-cover"
        />
    </motion.div>
);

export default ExploreCard;