import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function SocialResponsibility() {
    return (
        <>
            <Header />
            <div className="Ramillas">
                <div className="md:px-20 px-8">
                    <h1 className="md:text-[3.5rem] text-[28px] text-center mt-20">
                        Sustainability & Social Responsibilty
                    </h1>
                    <p className="md:text-[1rem] text-[16px] text-center mt-10 md:w-2/3 md:mx-auto mx-4">
                        In an era where luxury is becoming increasingly
                        conscious, Everidoor recognizes the imperative of making
                        positive contributions to both society and the
                        environment. Sustainability and social responsibility
                        are woven into the very fabric of our operations, rather
                        than being mere afterthoughts.
                    </p>
                </div>
                <h1 className="md:px-20 px-8 text-center md:text-[2rem] text-[28px] mt-24">
                    Sustainabilty Options
                </h1>
                <div className="md:px-20 px-8 md:flex gap-8 w-fit mx-auto mt-20 md:text-[1rem] text-[16px]">
                    <div className="flex gap-8">
                        <div className="relative group">
                            <img
                                src="Mask group_1.png"
                                alt=""
                                className="object-cover h-full w-full"
                            />
                            <p className="mt-4">Digital Over Print</p>
                            <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                                <div class="md:text-[18px] text-white  ">
                                    <p>As brands transition to </p>
                                    <p>advertising on Everidoor's</p>
                                    <p>platform and reduce their</p>
                                    <p>reliance on print media, we</p>
                                    <p>are collectively moving </p>
                                    <p>towards a more sustainable</p>
                                    <p>future. Digital displays offer</p>
                                    <p>an eco-friendly alternative </p>
                                    <p>to the waste generated by</p>
                                    <p>traditional print </p>
                                    <p>advertisements.</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative group">
                            <img
                                src="Mask group-1_1.png"
                                alt=""
                                className="object-cover h-full w-full"
                            />
                            <p className="mt-4">Material Sourcing</p>
                            <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                                <div class="md:text-[24px] text-white ">
                                    <p>Our commitment to</p>
                                    <p> environmental stewardship</p>
                                    <p>begins with the eco-friendly </p>
                                    <p>materials utilized in our</p>
                                    <p>digital displays.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-8 mt-16 md:mt-0">
                        <div className="relative group">
                            <img
                                src="Component 4.png"
                                alt=""
                                className="object-cover h-full w-full"
                            />
                            <p className="mt-4">Energy Efficiency</p>
                            <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                                <div class="md:text-[24px] text-white ">
                                    <p> Our cutting-edge </p>
                                    <p>technology prioritizes both</p>
                                    <p>display quality and energy</p>
                                    <p> efficiency, adhering to</p>
                                    <p>global standards.</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative group">
                            <img
                                src="Component 6.png"
                                alt=""
                                className="object-cover h-full w-full"
                            />
                            <p className="mt-4">Waste Management</p>
                            <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 md:group-hover:scale-y-100  transition-transform duration-700">
                                <div class="md:text-[24px] text-[8] text-white ">
                                    <p>Everidoor is an active</p>
                                    <p>participant in social</p>
                                    <p>causes that reflect our</p>
                                    <p>values, contributing</p>
                                    <p>both financial</p>
                                    <p>resources and</p>
                                    <p>volunteer efforts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="md:px-20 px-8 text-center text-[2rem] mt-32">
                    Social Responsibility
                </h1>
                <div className="md:px-20 px-8 md:flex gap-8 w-fit mx-auto mt-20 md:text-[1rem] text-[16px]">
                    <div class="relative group">
                        <img src="Component 7.png" alt="" class="object-cover h-full w-full" />
                        <p class="mt-4">Community Involvement</p>
                        <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                            <div class="text-[24px] text-white ">
                                <p>Everidoor is an active</p>
                                <p>participant in social</p>
                                <p>causes that reflect our</p>
                                <p>values, contributing</p>
                                <p>both financial</p>
                                <p>resources and</p>
                                <p>volunteer efforts.</p>
                            </div>
                        </div>
                    </div>
                    <div className="md:mt-0 mt-4 relative group">
                        <img
                            src="Component 8.png"
                            alt=""
                            className="object-cover h-full w-full"
                        />
                        <p className="mt-4">Inclusive Workforce</p>
                        <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                            <div class="text-[24px] text-white ">
                                <p>Our team is a mosaic</p>
                                <p>of diverse talents and </p>
                                <p>perspectives, fostering</p>
                                <p> an inclusive </p>
                                <p>environment that</p>
                                <p>enriches our company </p>
                                <p>culture and elevates</p>
                                <p>the luxury experience</p>
                                <p> we offer.</p>
                            </div>
                        </div>
                    </div>
                    <div className="md:mt-0 mt-4  relative group">
                        <img
                            src="Component 9.png"
                            alt=""
                            className="object-cover h-full w-full"
                        />
                        <p className="mt-4">Client Education</p>
                        <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                            <div class="text-[24px] text-white ">
                                <p>Everidoor is an active</p>
                                <p>participant in social</p>
                                <p>causes that reflect our</p>
                                <p>values, contributing</p>
                                <p>both financial</p>
                                <p>resources and</p>
                                <p>volunteer efforts.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="md:px-20 px-8 text-center md:text-[2rem] text-[28px] md:mt-32 mt-20">
                    Future Goals
                </h1>
                <div className="md:flex md:mx-16 mx-4 mt-16">
                    <div className="relative group ">
                        <img
                            src="Component 10.png"
                            alt=""
                            className=" object-cover"
                        />
                        <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                            <div class="text-[32px] text-white ">
                                <p> Transparency </p>
                            </div>
                        </div>
                    </div>
                    <p className="md:w-1/2 mx-auto md:text-[1rem] text-[16px] md:my-auto my-4 md:px-10 md:text-left text-center">
                        One of our key aspirations is to increase transparency
                        in our sustainability practices, empowering clients to
                        make informed choices.
                    </p>
                </div>
                <div className="md:px-20 flex md:flex-row flex-col-reverse md:mx-16 mx-4 mt-16 gap-4 md:gap-0">
                    <p className="md:w-1/2 mx-auto md:text-[1rem] text-[16px] my-auto md:px-10 md:text-left text-center">
                        We seek collaborations with organizations that share our
                        commitment to environmental and social well-being.
                    </p>
                    <div className="relative group">
                        <img
                            src="Component 13.png"
                            alt=""
                            className=" object-cover"
                        />
                        <div class="absolute flex items-end justify-start p-4 inset-0 bg-black/50 cursor-pointer transform scale-y-0 group-hover:scale-y-100 transition-transform duration-700">
                            <div class="text-[32px] text-white ">
                                <p>Partnership</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#F8F8F8] md:py-24 py-16 md:px-32 px-4 text-center text-[1.25rem] md:my-20 my-10">
                    In an era where luxury is becoming increasingly conscious,
                    Everidoor recognizes the imperative of making positive
                    contributions to both society and the environment.
                    Sustainability and social responsibility are woven into the
                    very fabric of our operations, rather than being mere
                    afterthoughts.
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SocialResponsibility;
