import React from "react";
import { VscThreeBars } from "react-icons/vsc";
import logo from "./images/everidoor.jpg";
import main from "./images/66.png";
import img1 from "./images/67.png";
import img2 from "./images/69.png";
import img3 from "./images/70.png";
import img4 from "./images/71.png";
// import bg from "./images/68.png";
import bg from "./images/presentation.png";
import Footer from "./Components/Footer";
import Header from "./Components/Header";

function Advertiser() {
    const backgroundImage = bg;
    return (
        <div>
            <Header />
            {/* Hero */}
            <section className="h-full flex flex-col items-center justify-center">
                <h1 className="mt-8 md:text-[1.25rem] text-xl font-light">
                    For Advertisers
                </h1>
                <h1 className="md:text-[3rem] md:leading-[3rem] text-3xl Ramillas mt-4 text-center md:mt-8">
                    Crafting Experiences, Captivating Audiences
                </h1>
                <img src={main} alt="" className="mx-auto w-[80%] mt-20" />
            </section>
            {/* Second */}
            <div>
                <p className="md:w-2/3 md:px-0 px-8 mx-auto text-center md:text-[20px] text-[16px] Ramillas xl:py-[5%] py-16">
                    At everidoor, we present a realm of luxury that transcends
                    expectations.
                    <br /> Our Luxury Display Network is where sophistication
                    meets innovation, where your brand's story unfolds on
                    digital
                    <br /> screens that stand as symbols of opulence.
                </p>
            </div>
            <section className="h-full md:flex justify-center pt-16 w-fit mx-auto md:px-0 px-4">
                <div className="flex justify-end md:mr-8 xl:w-[20%] lg:w-[30%] md:w-[50%] md:h-full">
                    <img src={img1} alt="" className="h-full w-full" />
                </div>
                <div className="md:w-[40%] flex items-center">
                    <div className="md:mr-0 lg:ml-16 ml-0">
                        <h1 className="text-[1.25rem] Ramillas md:text-left text-center md:mt-0 mt-8">
                            Elevate Your Brand through
                        </h1>
                        <h1 className="md:text-[3rem] text-[2rem] md:mt-1 Ramillas md:text-left text-center">
                            Luxury Advertising
                        </h1>
                        <p className="text-[1rem] mt-8 leading-[1.75rem] md:text-left text-center">
                            Unlock the power of precision with everidoor. Our
                            Luxury Display Network presents an opportunity to
                            captivate the most discerning audience—UHNIs.
                            Immerse your brand in a world where innovation meets
                            opulence, and let your story resonate like never
                            before.
                        </p>
                    </div>
                </div>
            </section>
            {/* Third */}
            <div
                className="min-h-screen h-full bg-cover flex flex-col items-center justify-center text-white relative mt-32"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPositionX: `48%`,
                    backgroundPositionY: "-3vw",
                }}
            >
                <h1 className="Ramillas md:text-6xl text-4xl text-center absolute 2xl:top-[25%] xl:top-[22%] md:top-[23%] top-[27%]">
                    Crafting Experiences
                    <br /> Captivating Audiences
                </h1>
                <div className="w-full bg-[#210B38]/40 border-t border-white/40 absolute bottom-0 md:py-20 py-8">
                    <p className="md:text-[18px] md:leading-8 w-[70%] md:text-center text-center mx-auto font-light">
                        Unlock the power of precision with everidoor. Our Luxury
                        Display Network presents an opportunity to
                        <br /> captivate the most discerning audience—UHNIs.
                        Immerse your brand in a world where innovation meets
                        opulence, and let your story resonate like never before.
                        <br />
                        <br />
                        <a
                            href="/Contact"
                            className="Ramillas mx-auto text-[20px] font-thin underline underline-offset-auto"
                        >
                            Join Our Network
                        </a>
                    </p>
                </div>
            </div>
            {/* Fourth */}
            <section className="h-full md:flex justify-center pt-16 w-fit mx-auto md:px-0 px-4">
                <div className="flex justify-end md:mr-8 xl:w-[20%] lg:w-[30%] md:w-[50%] md:h-full">
                    <img src={img2} alt="" className="h-full w-full" />
                </div>
                <div className="md:w-[40%] flex items-center">
                    <div className="md:mr-0 lg:ml-16 ml-0">
                        <h1 className="text-[1.25rem] Ramillas md:text-left text-center md:mt-0 mt-8">
                            Seize the Spotlight
                        </h1>
                        <h1 className="md:text-[3rem] text-[2rem] md:mt-1 Ramillas md:text-left text-center">
                            Elevate Your Brand
                        </h1>
                        <p className="text-[1rem] mt-8 leading-[1.75rem] md:text-left text-center">
                            Unlock the power of precision with everidoor. Our
                            Luxury Display Network presents an opportunity to
                            captivate the most discerning audience—UHNIs.
                            Immerse your brand in a world where innovation meets
                            opulence, and let your story resonate like never
                            before.
                        </p>
                    </div>
                </div>
            </section>
            {/* Fifth */}
            <div
                style={{
                    background: "rgb(233,163,245)",
                    backgroundImage:
                        "linear-gradient(90deg, rgba(233,163,245,1) 0%, rgba(20,15,46,1) 100%)",
                }}
                className="h-full py-16 md:flex gap-8 text-white mt-20"
            >
                <div>
                    <img src={img3} alt="" className="w-[700px]" />
                </div>
                <div className="border-white/25 md:border-l pl-8 md:mt-0 mt-4">
                    <h1 className="md:text-4xl text-3xl Ramillas">
                        Experience <i>Control</i>,
                        <br /> Define <i>Impact</i>
                    </h1>
                    <p className="md:text-lg mt-4 md:pr-16 pr-8">
                        At everidoor, we put the reins in your hands. Our
                        dashboard empowers you to curate, schedule, and modify
                        your content remotely. This level of control ensures
                        your brand's message remains aligned with your vision,
                        offering a level of personalization that's truly
                        unprecedented.
                    </p>
                </div>
            </div>
            {/* Sixth */}
            <section className="h-full md:flex justify-center pt-16 w-fit mx-auto md:px-0 px-4 mb-12">
                <div className="flex justify-end md:mr-8 xl:w-[20%] lg:w-[30%] md:w-[50%] md:h-full">
                    <img src={img4} alt="" className="object-fill h-full w-full" />
                </div>
                <div className="md:w-[40%] flex items-center">
                    <div className="md:mr-0 lg:ml-16 ml-0">
                        <h1 className="md:text-[3rem] text-[2rem] md:mt-1 mt-8 Ramillas md:text-left text-center">
                        Embrace <i>Innovation</i>,<br /> Embody <i>Luxury</i>
                        </h1>
                        <p className="text-[1rem] mt-8 leading-[1.75rem] md:text-left text-center">
                        Luxury is about more than just aesthetics; it's about
                        embracing innovation. With everidoor, you're not just
                        advertising; you're pioneering a new era of luxury
                        engagement. Join us in reshaping the landscape of luxury
                        advertising, where technology meets elegance in perfect
                        harmony.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Advertiser;
