import React, { useState } from "react";
import OutlookHeader from "./OutlookHeader";
import { useNavigate } from "react-router-dom";
import one from "../../images/Article Images/Article 2 (i).jpg";
import two from "../../images/Article Images/Article 2 (ii).jpg";
import three from "../../images/Article Images/Article 2 (iii).jpg";
import OutlookFooter from "./OutlookFooter";
import { motion } from "framer-motion";

function ArticleTwo() {
    const navigate = useNavigate();
    const [right, setRight] = useState(false);
    return (
        <motion.div
            initial={{ x: window.innerWidth }}
            animate={{ x: 0, transition: { ease: "linear" } }}
            exit={
                right
                    ? { x: -window.innerWidth, transition: { duration: 0.4 } }
                    : { x: window.innerWidth, transition: { duration: 0.4 } }
            }
        >
            {/* Fixed Elements */}
            <button
                type="button"
                className="p-2 px-4 bg-[#340c42] text-white rounded-sm fixed bottom-16 right-16 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    setRight(true);
                    navigate("/Outlook2023-Three");
                }}
            >
                {">"}
            </button>
            <button
                type="button"
                className="text-white p-2 px-4 bg-[#340c42] rounded-sm fixed bottom-16 right-28 opacity-70 hover:opacity-100 z-30"
                onClick={() =>
                    navigate(
                        "/Outlook2023/Navigating-the-Competitive-Landscape"
                    )
                }
            >
                {"<"}
            </button>
            {/* Article */}
            <div>
                <div className="w-full h-[46vh] md:h-[60vh] overflow-hidden">
                    <div className="bg-black/50 w-full h-[100%] md:h-[60vh] relative z-10 flex items-center">
                        <motion.h1
                            className="text-xl md:text-5xl w-[80%] mx-auto Ramillas text-white text-center"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: { delay: 0.5 },
                            }}
                        >
                            Luxury Giants Ascend: Chanel, Dior, Hermès, and
                            Gucci Among Fastest-Growing Global Brands
                        </motion.h1>
                    </div>
                    <motion.img
                        src={three}
                        alt=""
                        className="object-cover w-full relative  -top-[100%] h-full md:-top-[575px] lg:bottom-[150%]"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.5 },
                        }}
                    />
                </div>
            </div>
            <motion.div
                className="lg:p-16 p-4 text-lg lg:flex gap-3 text-justify lg:gap-8"
                initial={{ opacity: 0, x: -20 }}
                animate={{
                    opacity: 1,
                    x: 0,
                    transition: { delay: 0.5 },
                }}
            >
                <div className="lg:w-1/2 lg:p-4">
                    <p className="">
                        In the ever-evolving business landscape, a select group
                        of global brands is emerging as frontrunners, adapting
                        and excelling amid rapid changes. According to
                        Interbrand's Best Global Brands Report, this elite cadre
                        includes renowned names such as Chanel, Dior, Hermès,
                        and Gucci, which have secured their spots as some of the
                        world's twelve fastest-growing brands. This year's
                        report delves into how leading brands navigate the
                        shifting business terrain, pinpointing three pivotal
                        strengths that contribute to their success: direction,
                        agility, and participation.
                    </p>
                </div>
                <div className="border"></div>
                <div className="lg:w-1/2 lg:p-4">
                    <p>
                        Luxury, a sector synonymous with opulence and
                        exclusivity, has taken the lead in growth, soaring by an
                        impressive 22% to claim the title of the fastest-growing
                        sector in terms of average brand value change. Following
                        closely are the Sporting Goods sector with a 19% rise
                        and Financial Services with a commendable 16% increase.
                    </p>
                </div>
            </motion.div>
            <div className="lg:p-16 text-lg lg:flex lg:gap-8">
                <div className="w-fit p-4">
                    <img src={two} alt="" className="h-[400px]" />
                </div>
                <div className="lg:w-1/2 text-justify p-4">
                    <p>
                        Rebecca Robins, Global Chief Learning and Culture
                        Officer at Interbrand, emphasized the triumph of brands
                        that have sustained their commitment to long-term
                        ambitions. These brands, including Chanel, Dior, Hermès,
                        and Gucci, have not only stayed true to their core
                        identity but also managed to diversify their offerings
                        and customer experiences, ensuring that their brands
                        remain fresh and relevant.
                    </p>
                    <p className="mt-4">
                        The remarkable accomplishments of luxury brands are
                        highlighted by their brand value growth: Chanel at 32%,
                        Dior at 27%, Hermès at 27%, and Gucci at 23% in 2022.
                        These figures underscore the exceptional strategic
                        execution demonstrated by these brands in terms of
                        organizational direction, adaptability to market
                        changes, and seamless consumer engagement throughout
                        their customer journeys.
                    </p>
                </div>
            </div>
            <div className="lg:p-16 p-4 text-justify text-lg bg-[#353535] text-white">
                <p>
                    Among these luxury luminaries, Hermès stands out with a 27%
                    uplift in brand value, marking its consistent growth—a
                    remarkable feat in the history of the report. Dior, though
                    relatively new to the study since 2017, has swiftly
                    positioned itself as a top performer, also enjoying a 27%
                    increase in brand value this year.
                </p>
                <p className="mt-4">
                    Moreover, luxury brands have proven their resilience,
                    notably standing strong amidst substantial shifts in the top
                    100. Six luxury brands, including Gucci and Louis Vuitton,
                    secured spots among the top 15 risers across all industries.
                    These exceptional luxury leaders have withstood the test of
                    time, consistently ranking among the most valuable luxury
                    brands since the inception of the study in 2000.
                </p>
                <p className="mt-16">
                    This year, the cumulative value of brands featured in the
                    report crossed the $3 trillion threshold for the first time,
                    exhibiting a growth rate of 16%—the most substantial surge
                    since the report's inception over two decades ago. The elite
                    top 10 brands encompass Apple, Microsoft, Amazon, Google,
                    Samsung, Toyota, Coca-Cola, Mercedes-Benz, Disney, and Nike.
                </p>
                <p className="mt-4">
                    Gonzalo Brujó, Global CEO of Interbrand, commended these top
                    brands for defying conventional norms. He emphasized their
                    ability to cultivate robust relationships with customers,
                    extending brand equity beyond conventional product
                    boundaries. These brands stand out in terms of leadership,
                    empowerment, and adaptability, translating into their
                    tangible value.
                </p>
            </div>
            <div className="text-lg lg:flex gap-3 lg:gap-8">
                <div className="lg:w-2/3 p-4 text-justify  lg:p-20">
                    <p>
                        Rebecca Robins, Global Chief Learning and Culture
                        Officer at Interbrand, emphasized the triumph of brands
                        that have sustained their commitment to long-term
                        ambitions. These brands, including Chanel, Dior, Hermès,
                        and Gucci, have not only stayed true to their core
                        identity but also managed to diversify their offerings
                        and customer experiences, ensuring that their brands
                        remain fresh and relevant.
                    </p>
                    <p className="lg:mt-4 mt-3">
                        The remarkable accomplishments of luxury brands are
                        highlighted by their brand value growth: Chanel at 32%,
                        Dior at 27%, Hermès at 27%, and Gucci at 23% in 2022.
                        These figures underscore the exceptional strategic
                        execution demonstrated by these brands in terms of
                        organizational direction, adaptability to market
                        changes, and seamless consumer engagement throughout
                        their customer journeys.
                    </p>
                    <p className="lg:mt-16 mt-3">
                        In a world characterized by economic uncertainty, the
                        report underscores the importance of forging stronger
                        connections with consumers. Successful brands have
                        evolved beyond static offerings, leveraging technology
                        to create enriched consumer experiences and integrate
                        seamlessly into their lives—a strategy crucial for
                        maintaining relevance and sustained growth.
                    </p>
                </div>
                <div className="">
                    <img src={one} alt="" className="object-cover h-full" />
                </div>
            </div>
            <OutlookFooter />
        </motion.div>
    );
}

export default ArticleTwo;
