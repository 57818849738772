import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../Home";
import About from "../About";
import Partner from "../Partner";
import Advertiser from "../Advertiser";
import Contact from "../Contact";
import Outlook2023 from "./Outlook2023/Outlook2023";
import ArticleOne from "./Outlook2023/ArticleOne";
import ArticleTwo from "./Outlook2023/ArticleTwo";
import { AnimatePresence } from "framer-motion";
import OutlookHeader from "./Outlook2023/OutlookHeader";
import Investor from "../Pages/Investor";
import Press from "../Pages/Press";
import Insights from "../Pages/Insights";
import SocialResponsibility from "../Pages/SocialResponsibility";
import ScrollToTop from "./ScrollToTop";
import Policy from "../Policy";
import TermAndCondition from "../TermAndCondition";
import Subscribe from "../Subscribe";
import Locations from "../Pages/Locations";
import ArticleThree from "./Outlook2023/ArticleThree";
import ArticleFour from "./Outlook2023/ArticleFour";
import ArticleFive from "./Outlook2023/ArticleFive";
import ArticleSix from "./Outlook2023/ArticleSix";
import Header from "./Header";
import LetterOfIndtent from "../LetterOfIndtent";
import Page from "../Pages/Page";

function AnimatedRoutes() {
    const location = useLocation();
    const pathHasOutlook = location.pathname.includes("/Outlook2023");
    const [initialLoad, setInitialLoad] = useState(true);

    return (
        <AnimatePresence>
            <ScrollToTop />
            {pathHasOutlook ? <Header /> : null}
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Page />} />
                <Route path="/About" element={<About />} />
                <Route path="/Partner" element={<Partner />} />
                <Route path="/Advertiser" element={<Advertiser />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Investor" element={<Investor />} />
                <Route path="/Press" element={<Press />} />
                <Route path="/Insights" element={<Insights />} />
                <Route path="/page" element={<Page/>} />
                <Route
                    path="/SocialResponsibility"
                    element={<SocialResponsibility />}
                />
                <Route
                    path="/Outlook2023"
                    element={<Outlook2023 initialLoad={initialLoad} />}
                />
                <Route
                    path="/Outlook2023/Navigating-the-Competitive-Landscape"
                    element={
                        <ArticleOne
                            initialLoad={initialLoad}
                            setInitialLoad={setInitialLoad}
                        />
                    }
                />
                <Route
                    path="/Outlook2023/Luxury-Giants-Ascend"
                    element={<ArticleTwo />}
                />
                <Route
                    path="/Outlook2023-Three"
                    element={<ArticleThree />}
                />
                <Route
                    path="/Outlook2023-Four"
                    element={<ArticleFour />}
                />
                <Route
                    path="/Outlook2023-Five"
                    element={<ArticleFive />}
                />
                <Route
                    path="/Outlook2023-Six"
                    element={<ArticleSix />}
                />

                <Route
                    path="/Policy"
                    element={<Policy />}
                />
                <Route
                    path="/TermandCondition"
                    element={<TermAndCondition />}
                />
                <Route
                    path="/Subscribe"
                    element={<Subscribe />}
                />
                <Route
                    path="/Location"
                    element={<Locations />}
                />
                <Route
                    path="/letterofintent"
                    element={<LetterOfIndtent/>}
                />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
