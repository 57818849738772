import React from "react";
import logo from "../../images/everidoor.jpg";
import { Link } from "react-router-dom";

function OutlookFooter() {
    return (
        <div className="w-full h-full flex flex-col border-t pb-8 scale-75 xl:scale-100">
            <div className="md:flex justify-between  my-16 items-center xl:mx-[5rem]">
                <img src={logo} alt="" className="md:h-[38px] h-[20px] 2xl:ml-[5rem] " />
                <div className="md:flex gap-4 justify-end 2xl:mr-[5rem]">
                    <p className="text-[#424242] md:text-[14px] pr-8 text-[14px] text-left md:w-1/2 mt-3">
                        Stay attuned to the latest in luxury advertising and
                        partnership. Subscribe to our newsletter.
                    </p>
                    <button
                        type="button"
                        className="border-[#fe4d00] border text-black px-6 p-1 text-[12px] mt-3 hover:bg-[#fe4d00] hover:text-white"
                    >
                        SUBSCRIBE
                    </button>
                </div>
            </div>
            <div className="flex flex-col gap-[30px]">
                <div className="md:flex justify-between gap-[-4px] md:text-[14px] text-[11px] px-0  2xl:px-[80px] xl:mx-[5rem] ">
                    <p className="md:text-left text-center">
                        Copyright © 2024 everidoor. All Rights Reserved.
                    </p>
                    <p className="text-[#424242] opacity-80 md:text-[14px] text-[11px] text-center md:text-left cursor-pointer">
                        {" "}
                        PRIVACY POLICY | TERMS & CONDITIONS | CONTACT
                    </p>
                </div>
            </div>
        </div>
    );
}

export default OutlookFooter;