import React from "react";
import img1 from "../images/67.png";
import Header from "../Components/Header";
import HeaderHome from "../Components/HeaderHome";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import logo from "../../src/images/edoorwhite.png";
import locations from '../images/locations.png';
import sustainbility from '../images/sustainbility1.png';
import contact from '../images/contact.png';
function Page() {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  // Define styles for mobile phones
  const mobileStyles = {
    position: isMobile ? "absolute" : "unset",
    marginTop: isMobile ? "12rem" : "unset",
  };
  const mobilemargintop = {
    marginTop: isMobile ? "7rem" : "unset",
  };

  return (
    <div className="w-full h-full relative flex flex-col">
      <section className="w-full h-full relative">
        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713601893/IMG_4211_1_ewfqpp.png"
          className="w-full h-[50vh] object-cover  md:h-[100vh] md:object-none  xl:h-[100vh] relative inset-0 z-1"
          alt=""
        />
         {/* <video autoPlay loop  muted
          className="w-full h-[50vh] object-cover  md:h-[100vh] md:object-none  xl:h-[100vh] relative inset-0 z-1"
         >
            <source src="https://everidoor.s3.ap-south-1.amazonaws.com/Official_Website_Images/Sequence+01_3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}

        <HeaderHome />
        <div className="flex flex-col justify-center items-center absolute xl:top-[27vh] top-[29vw] right-[5vw] z-20">
          <img
            src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714058331/everidoor_ufunig.svg"
            className="w-[18vw] md:w-[10vw] mb-[0.8vw]  text-center"
            alt=""
          />
          <p className="md:text-[2.5vw] text-[6vw]  italic text-white Ramillas">
            The Epitome of
          </p>
          <p className="md:text-[3.5vw] text-[6vw] leading-normal -mt-[0.8vw] text-white z-20 Ramillas Ramillas">
            Luxury Advertising
          </p>
        </div>

        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713679778/Vector_1_tlgx3w.svg"
          className="absolute xl:top-[90vh] top-[50vw] w-[4vw] z-1 left-[50vw]"
          alt=""
        />
        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714026957/5293780_5_3_ytubvx.png"
          className="md:-mt-[14vw] -mt-[33vw] w-[50vw] z-0 left-0 md:w-[40vw]"
          alt=""
        />
      </section>
      <section className="w-full">
        <p className="flex justify-center text-[#604A01] text-[1rem] md:text-[1.5rem] px-[15px] md:px-[15vw] tracking-wide text-center md:-mt-[6vw] md:pb-48 -mt-[4vw] z-10 relative font-thin">
          Everidoor unveils a realm of opulence that exceeds every anticipation.
          Our Luxury Display Network seamlessly merges sophistication with
          innovation, offering a captivating stage where your brand's story
          elegantly unfurls on digital screens that epitomize extravagance.
        </p>
      </section>
      <section
        className="flex flex-col w-full gap-4 px-4 "
        style={mobilemargintop}
      >
        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714028246/5293780_5_2_kukn5o.png"
          className="md:-mt-[17vw] -mt-[22vw] w-[50vw] z-0 absolute right-0 md:w-[40vw]"
          alt=""
        />
        <div className="flex flex-col md:flex md:flex-row gap-4 ">
          <div className="w-full relative z-10 overflow-hidden">
            <Link
              to="/about"
              className="relative block h-full w-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <div className="w-full h-full transform transition duration-[2.5s] hover:scale-110">
                  <img
                    src="pexels-skitterphoto-2231814 1.png"
                    className="object-cover h-full w-full  z-10"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0"></div>
                </div>
              </div>
            </Link>
            <div className="absolute flex flex-col gap-1 bottom-[3vw] left-[3vw] z-40">
              <div className="flex gap-2">
                <Link to="/about">
                  <h1 className="text-white md:text-base text-sm">EXPLORE</h1>
                </Link>
                <img
                  src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713611933/Line_32_se6swc.svg"
                  className="w-[5vw]"
                  alt=""
                />
              </div>
              <Link to="/about">
                <h2 className="text-white text-3xl md:text-5xl  font-bold">
                  ABOUT US
                </h2>
              </Link>
            </div>
          </div>
          <div className="w-full relative overflow-hidden">
            <Link
              to="/Outlook2023"
              className="relative block h-full w-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <div className="w-full h-full transform transition duration-[2.5s] hover:scale-110">
                  <img
                    src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714059629/wowmolog_52358_luxury_hotel_lobby_with_small_vertical_standing__1ec93fa9-0171-4845-a271-4bf267a0358f_2_mzzvbe.png"
                    className="object-cover h-full w-full  z-10"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0"></div>
                </div>
              </div>
            </Link>
            <div className="absolute flex flex-col gap-1 bottom-[3vw] left-[3vw] z-40">
              <div className="flex gap-2">
                <Link to="/Outlook2023">
                  <h1 className="text-white md:text-base text-sm">EXPLORE</h1>
                </Link>
                <img
                  src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713611933/Line_32_se6swc.svg"
                  className="w-[5vw]"
                  alt=""
                />
              </div>
              <Link to="/Outlook2023">
                <h2 className="text-white text-3xl md:text-5xl font-bold">
                  LUXURY OUTLOOK
                </h2>
              </Link>
            </div>
          </div>
        </div>
        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714026957/5293780_5_3_ytubvx.png"
          className="md:-mt-[8vw] -mt-[14vw] w-[50vw] z-0 left-0 md:w-[40vw]"
          alt=""
        />
        <section className="px-4 pt-4 pb-[20vw] md:-mt-[15vw] md:px-16 md:pb-32 flex flex-col items-center gap-6 md:gap-10">
          <h1 className="text-center Ramillas text-2xl md:text-5xl font-medium leading-normal bg-gradient-to-r from-[#906E00] to-[#B48B05] text-transparent bg-clip-text max-w-screen-md w-full">
            The Ultimate Showcase
          </h1>
          <p className="text-center font-inter  font-light text-[#604A01] text-[1rem] text-base md:text-lg leading-7 max-w-screen-md w-full">
            Showcase your luxury offerings to an audience that values excellence
            and uniqueness. Gain access to a platform that perfectly embodies
            your brand's philosophy and worth.
          </p>
        </section>
        <div className="md:flex md:flex-row flex flex-col gap-4 ">
          <img
            src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714028246/5293780_5_2_kukn5o.png"
            className=" -mt-[20vw] w-[50vw] md:-mt-[17vw] z-0 absolute right-0 md:w-[40vw]"
            alt=""
          />
          <div className="w-full z-10 relative overflow-hidden">
            <Link
              to="/advertiser"
              className="relative block h-full w-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <div className="w-full h-full transform transition duration-[2.5s] hover:scale-110">
                  <img
                    src="pexels-cottonbro-studio-6474924 1.png"
                    className="object-cover h-full w-full  z-10"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0"></div>
                </div>
              </div>
            </Link>
            <div className="absolute flex flex-col gap-1 bottom-[3vw] left-[3vw] z-40">
              <div className="flex gap-2">
                <Link to="/advertiser">
                  <h1 className="text-white md:text-base text-sm">EXPLORE</h1>
                </Link>
                <img
                  src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713611933/Line_32_se6swc.svg"
                  className="w-[5vw]"
                  alt=""
                />
              </div>

              <Link to="/advertiser">
                <h2 className="text-white text-3xl md:text-5xl  font-bold">
                  ADVERTISER
                </h2>
              </Link>
            </div>
          </div>
          <div className="w-full relative overflow-hidden">
            <Link
              to="/location"
              className="relative block h-full w-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <div className="w-full h-full transform transition duration-[2.5s] hover:scale-110">
                  <img
                    src={locations}
                    className="object-cover h-full w-full  z-10"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0"></div>
                </div>
              </div>
            </Link>
            <div className="absolute flex flex-col gap-1 bottom-[3vw] left-[3vw] z-40">
              <div className="flex gap-2">
                <Link to="/location">
                  <h1 className="text-white text-sm md:text-base">EXPLORE</h1>
                </Link>
                <img
                  src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713611933/Line_32_se6swc.svg"
                  className="w-[5vw]"
                  alt=""
                />
              </div>
              <Link to="/location">
                <h2 className="text-white text-3xl md:text-5xl  font-bold">
                  LOCATIONS
                </h2>
              </Link>
            </div>
          </div>
        </div>
        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714026957/5293780_5_3_ytubvx.png"
          className="md:-mt-[8vw] -mt-[13vw] w-[50vw] z-0 left-0 md:w-[40vw]"
          alt=""
        />
      </section>
      <section className="pt-8 mb-[16vw] md:mb-[8vw] px-5 -mt-[12vw] md:-mt-[9vw] flex flex-col items-center gap-1">
        <p className="text-[#604A01] Interphases text-center font-normal text-base text-[1rem] md:text-[1.8vw]">
          At everidoor, we present a realm of luxury that transcends
          expectations.
        </p>
        <h1 className="font-primary Ramillas text-[1.3rem] md:text-5xl p-1 font-medium md:mt-1 bg-gradient-to-r from-[#906E00] to-[#B48B05] text-transparent bg-clip-text text-center leading-normal">
          Elevating brands to the pinnacle of prestige and aspiration.
        </h1>
      </section>

      <section className="p-4 md:px-8 flex flex-col md:m-24 items-center">
        <img
          src="https://res.cloudinary.com/dhwes67mf/image/upload/v1714028246/5293780_5_2_kukn5o.png"
          className=" -mt-[20vw] md:-mt-[17vw] w-[50vw] z-0 absolute right-0 md:w-[40vw]"
          alt=""
        />

        <div className="flex flex-col h-[140vw] md:h-full gap-4 md:flex-row md:gap-8">
          <div className="h-[50%] md:h-[30vw] md:w-[50%] relative overflow-hidden">
            <Link
              to="/SocialResponsibility"
              className="relative block h-full w-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <div className="w-full h-full transform transition duration-[2.5s] hover:scale-110">
                  <img
                    src={sustainbility}
                    className="object-cover h-full w-full  z-10"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0"></div>
                </div>
              </div>
            </Link>
            <div className="absolute bottom-4 left-4 md:bottom-8 md:left-8 z-40 flex flex-col gap-2">
              <div className="flex gap-2">
                <Link to="/SocialResponsibility">
                  <h1 className="text-white text-sm md:text-[1.5vw] md:text-base">
                    EXPLORE
                  </h1>
                </Link>
                <img
                  src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713611933/Line_32_se6swc.svg"
                  className="w-[10vw]"
                  alt=""
                />
              </div>
              <Link to="/SocialResponsibility">
                <h2 className="text-white text-3xl md:text-[3vw] font-bold">
                  SUSTAINABILITY
                </h2>
              </Link>
            </div>
          </div>
          <div className="h-[50%] md:w-[50%] md:h-[30vw] relative overflow-hidden">
            <Link
              to="/Contact"
              className="relative block h-full w-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <div className="w-full h-full transform transition duration-[2.5s] hover:scale-110">
                  <img
                    src={contact}
                    className="object-cover h-full w-full  z-10"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0"></div>
                </div>
              </div>
            </Link>
            <div className="absolute bottom-4 left-4 md:bottom-8 md:left-8 z-40 flex flex-col gap-2">
              <div className="flex gap-2">
                <Link to="/Contact">
                  <h1 className="text-white text-sm md:text-[1.5vw] md:text-base">
                    EXPLORE
                  </h1>
                </Link>
                <img
                  src="https://res.cloudinary.com/dhwes67mf/image/upload/v1713611933/Line_32_se6swc.svg"
                  className="w-[10vw]"
                  alt=""
                />
              </div>
              <Link to="/Contact">
                <h2 className="text-white text-3xl md:text-[3vw] font-bold">
                  CONTACT US
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Page;
