import React, { useEffect, useState } from 'react';
import "../Loader.css";

function Loader() {
    const [fontSize, setFontSize] = useState(5); // Initial font size

    useEffect(() => {
        const handleResize = () => {
            // Adjust font size based on screen width
            const newFontSize = window.innerWidth > 768 ? 5 : 10; // Example sizes, adjust as needed
            setFontSize(newFontSize);
        };

        handleResize(); // Initial font size adjustment

        const preloaderTimeout = setTimeout(() => {
            const ctnPreloader = document.getElementById('ctn-preloader');
            if (ctnPreloader) {
                ctnPreloader.classList.add('loaded');
                document.body.classList.remove('no-scroll-y');

                if (ctnPreloader.classList.contains('loaded')) {
                    setTimeout(() => {
                        const preloader = document.getElementById('preloader');
                        if (preloader) {
                            preloader.remove();
                        }
                    }, 1000);
                }
            }
        }, 3000);

        window.addEventListener('resize', handleResize); // Listen for window resize event

        return () => {
            clearTimeout(preloaderTimeout);
            window.removeEventListener('resize', handleResize); // Cleanup event listener
        };
    }, []);

    return (
        <section>
            <div id="preloader">
                <div id="ctn-preloader" className="ctn-preloader">
                    <div className="animation-preloader">
                        <div className="txt-loading font-bold">
                            <span data-text-preloader="e" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='everi'>e</p>
                            </span>
                            <span data-text-preloader="v" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='everi'>v</p>
                            </span>
                            <span data-text-preloader="e" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='everi'>e</p>
                            </span>
                            <span data-text-preloader="r" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='everi'>r</p>
                            </span>
                            <span data-text-preloader="i" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='everi'>i</p>
                            </span>
                            <span data-text-preloader="d" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='door'>d</p>
                            </span>
                            <span data-text-preloader="o" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='door'>o</p>
                            </span>
                            <span data-text-preloader="o" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='door'>o</p>
                            </span>
                            <span data-text-preloader="r" className="letters-loading" style={{ fontSize: `${fontSize}vw` }}>
                                <p className='door'>r</p>
                            </span>
                        </div>
                    </div>
                    <div className="loader-section section-left "></div>
                    <div className="loader-section section-right"></div>
                </div>
            </div>
        </section>
    );
}

export default Loader;
