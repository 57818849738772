import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OutlookHeader from "./OutlookHeader";
import one from "../../images/Article Images/Article 6 (i).jpg";

import OutlookFooter from "./OutlookFooter";
import { motion } from "framer-motion";

function ArticleSix() {
    const navigate = useNavigate();
    const [right, setRight] = useState(false);
    return (
        <motion.div
            initial={{ x: window.innerWidth }}
            animate={{
                x: 0,
                transition: { ease: "linear" },
            }}
            exit={
                right
                    ? { x: -window.innerWidth, transition: { duration: 0.4 } }
                    : { x: window.innerWidth, transition: { duration: 0.4 } }
            }
        >
            {/* Fixed Elements */}
            <button
                type="button"
                className="p-2 px-4 bg-[#340c42] text-white rounded-sm fixed bottom-16 right-16 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    setRight(true);
                    navigate("/Outlook2023");
                }}
            >
                {">"}
            </button>
            <button
                type="button"
                className="text-white p-2 px-4 bg-[#340c42] rounded-sm fixed bottom-16 right-28 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    navigate("/Outlook2023-Five");
                }}
            >
                {"<"}
            </button>
            {/* Article */}
            <div>
                <div className="w-full h-[46vh] md:h-[60vh] overflow-hidden">
                    <div className="bg-black/50 w-full h-[100%] md:h-[60vh] relative z-10 flex items-center">
                        <motion.h1
                            className="text-xl md:text-5xl w-[80%] mx-auto Ramillas text-white text-center"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: { delay: 0.5 },
                            }}
                        >
                            "How is LVMH a Beacon of Luxury, Elegance, and Innovation?"
                        </motion.h1>
                    </div>
                    <motion.img
                        src={one}
                        alt=""
                        className="object-cover w-full relative  -top-[100%] h-full md:-top-[575px] lg:bottom-[150%]"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.5 },
                        }}
                    />
                </div>
                <motion.div
                    className="md:p-16 text-lg  md:flex"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.5 },
                    }}
                >
                    <div className="w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            LVMH, or Louis Vuitton Moët Hennessy, epitomizes the essence of luxury, richness, and sophistication. Founded in 1987, this union of two legendary French luxury houses, Louis Vuitton and Moët Hennessy, under the visionary leadership of Chairman and CEO Bernard Arnault, marked a significant milestone in the world of luxury.                        </p>
                        <p className="mt-4 text-justify">
                            At its core, LVMH is driven by a business model centered on developing and acquiring high-end luxury brands known for exceptional quality and exclusivity. Positioned at the highest price points in their respective categories, LVMH's brands cater to elite consumers willing to invest in luxury goods. With a diverse portfolio of over 70 luxury brands spanning fashion, watches, cosmetics, wine, spirits, and more, LVMH holds the distinction of being the world's largest luxury conglomerate.                        </p>
                    </div>
                    <div className=" w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            The company's strategic approach involves a range of revenue streams to mitigate risks and capitalize on growth opportunities across various luxury segments. LVMH's long-term vision focuses on maintaining its position as a global leader in luxury, achieved through strategic investments and acquisitions that align with its portfolio and growth objectives.
                        </p>
                        <p className="mt-4 text-justify">
                            Sustainability is a key commitment, with LVMH setting ambitious targets to reduce carbon emissions and achieve net-zero emissions by 2050, demonstrating its dedication to eco-friendly practices.
                        </p>
                    </div>
                </motion.div>
                <div className="mt-8 bg-slate-900 text-white p-6 md:p-16 text-lg md:flex">
                    <div>
                        <img src={one} alt="" />
                    </div>
                    <div className="mt-6 md:px-16">
                        <p className="text-justify">
                            In terms of marketing, LVMH collaborates with high-profile celebrities, artists, and influencers to craft captivating campaigns that align the brand with popular culture and generate excitement around new collections and products. Its advertising strategies strike a balance between tradition and modernity, capturing the essence of its brands and the aspiration for an unparalleled lifestyle.
                        </p>
                        <p className="mt-4 text-justify">
                            LVMH has evolved into a powerhouse of iconic brands spanning fashion, beauty, and more, redefining luxury for the modern world. It stands as a symbol of exclusivity and style, leaving an indelible mark on the global landscape of luxury.
                        </p>
                        {/* <p className="mt-6 lg:mt-16 text-justify">
                            In a highly competitive market, differentiation is
                            crucial. Brands must leverage their unique
                            narratives and values to stand out. The growing
                            influence of platforms like TikTok in skincare
                            trends presents an opportunity for luxury brands to
                            connect with new audiences and boost visibility.
                        </p>
                        <p className="mt-6 lg:mt-4 text-justify">
                            To succeed, luxury brands must deliver an
                            exceptional customer experience. This encompasses
                            not only product quality but also personalized
                            customer service. As the industry evolves, luxury
                            brands that offer bespoke experiences and align with
                            customer values are poised for success.
                        </p> */}
                    </div>
                </div>
            </div>
            <OutlookFooter />
        </motion.div>
    );
}

export default ArticleSix;