import React from "react";
import aboutusmain from "./images/aboutusmain.png";
import { VscThreeBars } from "react-icons/vsc";
import logo from "./images/everidoor.jpg";
import flower from "./images/60.png";
import third from "./images/63.png";
import bg from "./images/62.png";
import four from "./images/square.png";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { Link } from "react-router-dom";
function About() {
    const backgroundImage = bg;
    return (
        <div>
            <Header />
            {/* Hero */}
            <section className="min-h-fit h-full">
                <div className="">
                    <img
                        src={aboutusmain}
                        alt="background"
                        className="md:w-[90%] ob w-full md:h-full h-[30vh]  mx-auto mt-[10%] md:mt-0"
                    />
                    <div
                        style={{
                            background: "rgb(233,163,245)",
                            backgroundImage:
                                "linear-gradient(90deg, rgba(233,163,245,1) 0%, rgba(20,15,46,1) 100%)",
                        }}
                        className="max-x-[650px] sm:text-1.5xl py-10 md:pr-28 pl-8 pr-8 text-white Caudex lg:text-5xl md:w-1/2 block md:relative bottom-72 left-[100%] md:-translate-x-[100%] md:text-right text-center"

                    >
                        Crafting <i>Luxury Experiences</i> Through{" "}
                        <i>Innovation</i>
                    </div>
                </div>
            </section>
            {/* Second */}
            <section className="h-full md:flex items-center justify-center md:pb-24 md:mt-0 md:my-0 md:mb-0 my-8 mb-3 ">
                <img
                    src={flower}
                    alt=""
                    className="md:h-[500px] h-[200px] mx-auto md:mx-0"
                />
                <div className="md:w-1/3 md:mx-16 mx-8 md:border-l-2 md:px-8 md:mt-0 ">
                    <h1 className="Caudex md:text-6xl text-4xl font-light text-center md:text-left mt-3">
                        Redefining Luxury Advertising
                    </h1>
                    <p className="md:text-2xl mt-8 text-[#424242]">
                        At everidoor, we believe that luxury is not just a
                        status;{" "}
                        <span className="font-bold">it's an experience.</span>{" "}
                        <br />
                        <br />
                        Our journey began with a vision to revolutionize how
                        luxury brands connect with their discerning audiences.
                        We envisioned a world where innovation and elegance
                        seamlessly intertwine, creating experiences that
                        resonate with those who appreciate the finer things in
                        life.
                    </p>
                </div>
            </section>
            {/* Third */}
            <section>
                <div
                    style={{
                        backgroundImage: `url(${four})`,
                        backgroundPositionX: "center",
                        backgroundSize: "cover",
                        // background: "rgb(144,69,167)",
                        // backgroundImage:
                        //     "radial-gradient(circle, rgba(144,69,167,1) 0%, rgba(5,3,33,1) 100%)",
                    }}
                    className="h-[700px] flex items-end py-12"
                >
                    <h1 className="text-white Caudex xl:text-6xl lg:text-5xl text-vw-4xl text-center w-full relative xl:bottom-[20%] 2xl:bottom-[5%] lg:bottom-36 bottom-48 md:bottom-48">
                        Elevating{" "}
                        <span className="font-bold italic">Brands</span>,
                        Elevating{" "}
                        <span className="font-bold italic">Experiences</span>
                    </h1>
                </div>
                <div className="md:px-24 px-8 py-8 xl:mt-0 -mt-40 lg:-mt-36">
                    <h1 className="text-2xl text-gray-700 italic font-bold">
                        We don't just offer advertising; we craft experiences.{" "}
                    </h1>
                    <p className="md:text-xl md:mt-0 mt-4 text-[#424242]">
                        Our Luxury Display Network isn't just a canvas for
                        messages; it's a gateway to emotions, aspirations, and
                        the essence of luxury. Through carefully curated
                        placements in exclusive locales, we ensure that every
                        interaction with our screens is an encounter with
                        elegance, distinction, and opulence.
                    </p>
                </div>
            </section>
            {/* Third */}
            <section className="min-h-screen h-full md:flex">
                <div className="md:w-1/2 flex flex-col items-center justify-center py-8 md:py-0">
                    <div className="w-[80%]">
                        <h1 className="text-[#340C42] Caudex md:text-5xl text-3xl">
                            <span className="font-bold">
                                Precision in Every Pixel:
                            </span>{" "}
                            Technology Meets Elegance
                        </h1>
                        <p className="mt-8 md:text-xl text-[#424242]">
                            At the core of our success is the fusion of luxury
                            with technology. Our proprietary dashboard empowers
                            both our display Network and advertisers, offering
                            seamless control over content remotely. <br />
                            <br />
                            Display Network transform their spaces into
                            digital art galleries, while advertisers curate
                            narratives that resonate with the elite.{" "}
                            <span className="font-bold">
                                This technology-driven harmony is what sets
                                everidoor apart.
                            </span>
                        </p>
                    </div>
                </div>
                <div className="md:w-1/2">
                    <div
                        style={{
                            background: "rgb(144,69,167)",
                            backgroundImage:
                                "radial-gradient(circle, rgba(144,69,167,1) 0%, rgba(5,3,33,1) 100%)",
                        }}
                        className="h-full md:w-1/2 flex items-end py-12 mx-auto"
                    >
                        <img
                            src={third}
                            alt=""
                            className="mx-auto md:scale-125 relative md:bottom-[100%] md:translate-y-[125%]"
                        />
                    </div>
                </div>
            </section>
            {/* Fourth */}
            <div
                className="min-h-[70vh] h-[542px] bg-cover md:mt-60 mt-2 mb-4 md:mb-52 flex flex-col items-center justify-center "
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <h1 className="md:w-2/4 text-white  text-sm Ramillas  leading-relaxed  text-center md:pt-0 md:px-0 px-8">
                    <p className="md:text-[42px] text-[20px]">Your Journey Starts Here:</p> <p className="md:text-[20px] md:mt-2 font-extralight">Partner with everidoor</p>
                </h1>
                <Link to={'/Contact'}>
                    <div className=" Ramillas block md:hidden mt-6 text-[12px] text-white px-6 py-2  md:mb-4  md:mt-4 mb-[20%] cursor-pointer underline ">
                        JOIN OUR NETWORK
                    </div>
                </Link>
                <div className=" text-center  md:py-0 px-16">
                    <p className="md:p-16  md:text-[20px] font-extralight  text-white ">
                        <p>As we continue our journey of innovation and elegance, we invite you to be a part of our story. Whether you're a display provider</p>
                        <p>looking to transform spaces or an advertiser seeking to captivate the elite, everidoor is your gateway to a world where luxury knows no bounds.</p>
                        <p>Join us and redefine how luxury stories are told.</p>
                    </p>
                </div>

                <Link to={'/Contact'}>
                    <div className=" Ramillas hidden md:block text-[20px] text-white px-6 py-2  md:mb-4  md:mt-4 mb-[20%] cursor-pointer underline ">
                        JOIN OUR NETWORK
                    </div>
                </Link>
            </div>
            <Footer />
        </div>
    );
}

export default About;
