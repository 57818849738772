import React from "react";
import Footer from "../Components/Footer";
import { BsSearch } from "react-icons/bs";
import Header from "../Components/Header";

function Press() {
    return (
        <>
            <Header />
            <div className="md:px-20 px-8">
                <div className="Ramillas">
                    <div>
                        <h1 className="md:text-[3rem] text-[1.5rem] text-center mt-20">
                            Press
                        </h1>
                        <img
                            src="Press/Mask group.png"
                            alt=""
                            className="mx-auto mt-10"
                        />
                    </div>
                    <div className="flex md:flex-row flex-col justify-between md:py-16 py-4">
                        <div className="border-b-2 flex items-center md:w-1/3">
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Search..."
                                className="w-full py-2 outline-none"
                            />
                            <BsSearch/>
                        </div>
                        <select
                            name=""
                            id=""
                            className="border-b-2 py-2 pr-24 text-gray-400 outline-none"
                        >
                            <option value="">Sort By</option>
                            <option value="">Newest</option>
                            <option value="">Oldest</option>
                        </select>
                    </div>
                    <div className="md:mt-0 mt-8">
                        <div className="flex md:flex-row flex-col md:gap-12 gap-4">
                            <img src="Press/Rectangle 58.png" alt="" />
                            <div>
                                <h1 className="md:md:text-[2rem] text-[1.5rem] cursor-pointer">
                                    Everidoor signs up deal with The Palm ,
                                    Dubai
                                </h1>
                                <p className="text-[1rem] text-[#909090] mt-4">
                                    Press Release | Sept 22, 2023
                                </p>
                            </div>
                        </div>
                        <hr className="my-16" />
                        <div className="flex md:flex-row flex-col md:gap-12 gap-4">
                            <img src="Press/Rectangle 63.png" alt="" />
                            <div>
                                <h1 className="md:text-[2rem] text-[1.5rem] cursor-pointer">
                                    Redefining Elegance: How Everidoor Crafted a
                                    Timeless Campaign for Louis Vuitton
                                </h1>
                                <p className="text-[1rem] text-[#909090] mt-4">
                                    Press Release | Sept 22, 2023
                                </p>
                            </div>
                        </div>
                        <hr className="my-16" />
                        <div className="flex md:flex-row flex-col md:gap-12 gap-4">
                            <img src="Press/Rectangle 60.png" alt="" />
                            <div>
                                <h1 className="md:text-[2rem] text-[1.5rem] cursor-pointer">
                                    Unveiling Opulence: Everidoor Sets New
                                    Standards in Luxury Advertising
                                </h1>
                                <p className="text-[1rem] text-[#909090] mt-4">
                                    Press Release | Sept 22, 2023
                                </p>
                            </div>
                        </div>
                        <hr className="my-16" />
                        <div className="flex md:flex-row flex-col md:gap-12 gap-4">
                            <img src="Press/Rectangle 61.png" alt="" />
                            <div>
                                <h1 className="md:text-[2rem] text-[1.5rem] cursor-pointer">
                                    The Pinnacle of Prestige: Everidoor's Luxury
                                    Brand Portfolio Unveiled
                                </h1>
                                <p className="text-[1rem] text-[#909090] mt-4">
                                    Press Release | Sept 22, 2023
                                </p>
                            </div>
                        </div>
                        <hr className="my-16" />
                        <div className="flex md:flex-row flex-col md:gap-12 gap-4">
                            <img src="Press/Rectangle 62.png" alt="" />
                            <div>
                                <h1 className="md:text-[2rem] text-[1.5rem] cursor-pointer">
                                    The Pinnacle of Prestige: Everidoor;s Luxury
                                    Brand Portfolio Unveiled
                                </h1>
                                <p className="text-[1rem] text-[#909090] mt-4">
                                    Press Release | Sept 22, 2023
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3   w-full items-center justify-center md:mt-36 mt-8">
                        <div className="flex  gap-5   w-full items-center justify-center ">
                            <button type="button" className="text-gray-400">
                                {"< "}Previous
                            </button>
                            <p>1</p>
                            <button type="button" className="text-gray-400">
                                Next{" >"}
                            </button>
                        </div>
                        <div className="border-b w-[99%]"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Press;
