import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OutlookHeader from "./OutlookHeader";
import main from "../../images/Article Images/Article 1 (i).jpg";
import OutlookFooter from "./OutlookFooter";
import { motion } from "framer-motion";

function ArticleOne({ initialLoad, setInitialLoad }) {
    const navigate = useNavigate();
    const [right, setRight] = useState(false);
    return (
        <motion.div
            initial={{ x: window.innerWidth }}
            animate={{
                x: 0,
                transition: { ease: "linear" },
            }}
            exit={
                right
                    ? { x: -window.innerWidth, transition: { duration: 0.4 } }
                    : { x: window.innerWidth, transition: { duration: 0.4 } }
            }
        >
            {/* Fixed Elements */}
            <button
                type="button"
                className="p-2 px-4 bg-[#340c42] text-white rounded-sm fixed bottom-16 right-16 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    setRight(true);
                    navigate("/Outlook2023/Luxury-Giants-Ascend");
                }}
            >
                {">"}
            </button>
            <button
                type="button"
                className="text-white p-2 px-4 bg-[#340c42] rounded-sm fixed bottom-16 right-28 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    setInitialLoad(false);
                    navigate("/Outlook2023");
                }}
            >
                {"<"}
            </button>
            {/* Article */}
            <div>
                <div className="w-full h-[46vh] md:h-[60vh] overflow-hidden">
                    <div className="bg-black/50 w-full h-[100%] md:h-[60vh] relative z-10 flex items-center">
                        <motion.h1
                            className="text-xl md:text-5xl w-[80%] mx-auto Ramillas text-white text-center"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: { delay: 0.5 },
                            }}
                        >
                            "Navigating the Competitive Landscape: Luxury
                            Brands' Evolution in the Beauty Industry"
                        </motion.h1>
                    </div>
                    <motion.img
                        src={main}
                        alt=""
                        className="object-cover w-full relative md:bottom-[220%]  bottom-[100%]"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.5 },
                        }}
                    />
                </div>
                <motion.div
                    className="md:p-16 text-lg  md:flex"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.5 },
                    }}
                >
                    <div className="w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            In the dynamic landscape of the beauty industry,
                            future prospects appear as exciting as recent
                            history. With an unprecedented demand for skincare
                            and makeup, luxury brands are racing to introduce
                            new offerings that cater to this growing trend.
                            However, in an oversaturated market, the challenge
                            lies in distinguishing oneself. Major luxury brands
                            like Stella McCartney, Hermès, and Valentino have
                            joined the beauty realm, indicating that the absence
                            of a skincare or beauty line is a missed
                            opportunity.
                        </p>
                        <p className="mt-4 text-justify">
                            Notably, industry giants are making substantial
                            investments to solidify their presence in luxury
                            beauty. LVMH appointed a new chairman and CEO for
                            its beauty division, while Kering established a
                            dedicated beauty expertise division. This heightened
                            competition stems from the exponential growth of
                            luxury skincare interest, backed by data showing a
                            57% rise in demand over four years.
                        </p>
                    </div>
                    <div className=" w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            The luxury beauty market is increasingly
                            competitive, projected to reach $580 billion in
                            global retail sales by 2027. Skincare, the largest
                            category, is expected to grow from $190 billion to
                            $260 billion between 2022 and 2027, driven by
                            scientific innovation and consumer preference for
                            efficacy-focused products. The most sought-after
                            luxury beauty brands, including Estée Lauder,
                            Lancome, and La Mer, witness substantial search
                            volumes, indicative of heightened interest.
                        </p>
                        <p className="mt-4 text-justify">
                            Despite economic challenges, consumers continue to
                            invest in self-care and luxury experiences,
                            bolstering the beauty industry's resilience.
                            Informed by data and educated preferences, modern
                            beauty consumers meticulously research products
                            before purchase. Ingredients like Retinol, Vitamin
                            C, and Hyaluronic Acid are among the most
                            searched-for, underpinning the rise of science-based
                            skincare.
                        </p>
                    </div>
                </motion.div>
                <div className="mt-8 bg-slate-900 text-white p-8 md:p-16 text-lg md:flex">
                    <div>
                        <img src={main} alt="" />
                    </div>
                    <div className="mt-6 md:px-16">
                        <p className="text-justify">
                            Consumers seek niche sustainable brands that offer a
                            holistic experience rather than mere products. The
                            key to entering the luxury beauty arena lies in
                            aligning products with brand identity, ensuring
                            quality and relevance. Tom Ford's strategic sequence
                            of launching fragrance, makeup, and skincare
                            exemplifies this approach.
                        </p>
                        <p className="mt-4 text-justify">
                            Moreover, luxury beauty products are expected to
                            embody sustainability, efficacy, and uniqueness.
                            This is especially true for brands like Augustinus
                            Bader, Noble Panacea, La Mer, and La Prairie, which
                            invest heavily in research and development to offer
                            cutting-edge solutions.
                        </p>
                        <p className="mt-6 lg:mt-16 text-justify">
                            In a highly competitive market, differentiation is
                            crucial. Brands must leverage their unique
                            narratives and values to stand out. The growing
                            influence of platforms like TikTok in skincare
                            trends presents an opportunity for luxury brands to
                            connect with new audiences and boost visibility.
                        </p>
                        <p className="mt-6 lg:mt-4 text-justify">
                            To succeed, luxury brands must deliver an
                            exceptional customer experience. This encompasses
                            not only product quality but also personalized
                            customer service. As the industry evolves, luxury
                            brands that offer bespoke experiences and align with
                            customer values are poised for success.
                        </p>
                    </div>
                </div>
            </div>
            <OutlookFooter />
        </motion.div>
    );
}

export default ArticleOne;