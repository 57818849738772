import React, { useState, useEffect } from "react";
import { motion, useCycle, useScroll } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import global from '../images/global.png';
import logo from "../images/everidoor.jpg";
import { VscThreeBars } from "react-icons/vsc";
import { GrClose } from "react-icons/gr";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

const MenuButton = ({ onClick, isOpen }) => {
  return (
    <motion.button
      className="absolute z-[100] menu-button"
      onClick={onClick}
      animate={isOpen ? "open" : "closed"}
      initial={false}
    >
      <svg
        width="23"
        height="23"
        style={{ margin: "4px 0 0 2px" }}
        viewBox="0 0 23 23"
      >
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
        />
      </svg>
    </motion.button>
  );
};

const slideVerticalAnimation = {
  open: {
    x: 0,
    display: "block",
    transition: {
      duration: 0.3,
      type: "linear",
    },
  },
  close: {
    x: -300,
    transition: {
      duration: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const Header = () => {
  const [isOpen, toggleDropdown] = useCycle(false, true);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState();
  console.log(currentPage);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setCurrentPage(1);
        break;
      case "/About":
        setCurrentPage(2);
        break;
      case "/Advertiser":
        setCurrentPage(3);
        break;
      case "/Partner":
        setCurrentPage(4);
        break;
      case "/Location":
        setCurrentPage(5);
        break;
      case "/Contact":
        setCurrentPage(6);
        break;
      default:
        setCurrentPage(0);
        break;
    }
  }, [location.pathname]);

  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [display, setDisplay] = useState(false);
  const [openmenu, setOpenmenu] = useState(false)
  function update() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
      setDisplay(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev && isOpen) {
      setHidden(true);
    }
    if(scrollY?.current > 140 && scrollY?.current > scrollY?.prev){
      setDisplay(true)
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  }, [scrollY]);

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -25 },
  };

  const navigate = useNavigate();

  useEffect(() => {
    const closeMenuOnClickOutside = (event) => {
      const menuButton = document.querySelector(".menu-button");
      const dropdownContainer = document.querySelector(".dropdown-container");
  
      if (
        isOpen &&
        menuButton &&
        dropdownContainer &&
        !menuButton.contains(event.target) &&
        !dropdownContainer.contains(event.target)
      ) {
        toggleDropdown();
      }
    };
  
    document.addEventListener("click", closeMenuOnClickOutside);
  
    return () => {
      document.removeEventListener("click", closeMenuOnClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  return (
    <>
      {isOpen || !display ? (
      <motion.div
        variants={variants}
        animate={hidden ? "hidden" : "visible"}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        className="fixed md:left-8 md:top-8 top-6 left-4 z-[150]"
      >
        <MenuButton onClick={toggleDropdown} isOpen={isOpen} />
      </motion.div>
      ) : null}
      <div className="w-full flex items-center justify-evenly h-[10vh] pt-8 pb-16 px-8 bg-white ">
        <motion.div
          className="dropdown-container bg-white w-[300px] fixed h-full z-[100] top-0 left-0 pt-16 border-r shadow-2xl"
          initial="close"
          animate={isOpen ? "open" : "close"}
          variants={slideVerticalAnimation}
        >
          <motion.div className="dropdown" initial="left">
            <motion.div className="">
            <ul className="p-8 text-black Ramillas text-[1.5rem]">
                                <li
                                    className={`mb-4 ${currentPage === 1
                                        ? "text-[#fe4e03]"
                                        : ""
                                        }`}
                                >
                                    <Link to={"/"} className="mb-4 pr-20">
                                        Home
                                    </Link>
                                </li>
                                <li
                                    className={`mb-4 ${currentPage === 2
                                        ? "text-[#fe4e03]"
                                        : ""
                                        }`}
                                >
                                    <Link to={"/About"} className="mb-4 pr-20">
                                        About
                                    </Link>
                                </li>
                                <li
                                    className={`mb-4 ${currentPage === 3
                                        ? "text-[#fe4e03]"
                                        : ""
                                        }`}
                                >
                                    <Link
                                        to={"/Advertiser"}
                                        className="mb-4 pr-16"
                                    >
                                        Advertiser
                                    </Link>
                                </li>
                                <li
                                    className={`mb-4 ${currentPage === 4
                                        ? "text-[#fe4e03]"
                                        : ""
                                        }`}
                                >
                                    <Link
                                        to={"/Partner"}
                                        className="mb-4 pr-20"
                                    >
                                        Partner
                                    </Link>
                                </li>
                                <li
                                    className={`mb-4 ${currentPage === 5
                                        ? "text-[#fe4e03]"
                                        : ""
                                        }`}
                                >
                                    <Link to={"/Location"} className="mb-4 pr-16">
                                        Locations
                                    </Link>
                                </li>
                                <li
                                    className={`mb-4 ${currentPage === 6
                                        ? "text-[#fe4e03]"
                                        : ""
                                        }`}
                                >
                                    <Link
                                        to={"/Contact"}
                                        className="mb-4 pr-20"
                                    >
                                        Contact
                                    </Link>
                                </li>
            </ul>
            </motion.div>
          </motion.div>
        </motion.div>
        <div className="absolute  flex md:h-[20px] min-[300px]:top-[25px] h-[20px] w-[120px]
                min-[300px]:left-[45%] md:left-[50%] left-[50%] -translate-x-1/3 md:top-[40px] top-[32px]">
          <img
            alt="everidoor"
            className=" cursor-pointer"
            onClick={() => navigate("/")}
            src={logo}
          />
        </div>
        <div className="flex mt-2 w-full justify-center">
          <div
            onClick={() => navigate("/Contact")}
            className="border-[#B48B05] border-opacity-25 mr-2  border  md:px-4 px-1 md:text-md text-[12px] font-light  cursor-pointer  min-[300px]:w-[75px]  md:w-[188px] flex items-center justify-center absolute md:top-[40px]  min-[300px]:top-[20px] min-[300px]:right-[2px]  top-[80px] md:right-8 hover:bg-gradient-to-r  transition duration-300 ease-in-out"
          >
            <div className="flex justify-center md:w-[188px] md:h-[32px]  text-center items-center">
              <span className="hidden md:inline text-[#B48B05] hover:text-[#F8C933] transition duration-300 ease-in-out">JOIN OUR NETWORK</span>
            </div>
            <span className="md:hidden flex justify-center items-center gap-2 text-[#252525] w-[72px] h-[32px]">
              JOIN <img src={global} alt="global" className="w-[14px] h-[14px]" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
