import React, { useState } from "react";
import Header from "../Components/Header";
import { CountryDropdown } from "react-country-region-selector";
import Footer from "../Components/Footer";
import PhoneInput from "react-phone-number-input";
import "../PhoneInput.css";
import { useForm, ValidationError } from "@formspree/react";

function Investor() {
    const [country, setCountry] = useState("");
    const [value, setValue] = useState();
    const [state, handleSubmit] = useForm("mwkdqorn");
    if (state.succeeded) {
        return (
            <section>
                <Header />
                <div className="flex items-center justify-center">
                    <p className="text-lg">
                        Thanks for joining ! Stay tuned for an enchanting
                        message from our team
                    </p>
                </div>
            </section>
        );
    }

    return (
        <div>
            <Header />
            {/* Hero */}
            <section>
                <div className="flex justify-between lg:flex-row flex-col-reverse md:mt-0 mt-20">
                    <div className="lg:mt-0 mt-8">
                        <h1 className="tracking-wider px-8 text-[11px] font-semibold opacity-75">
                            <span className="text-gray-400">CONTACT</span> |
                            INVESTOR'S LOGIN
                        </h1>
                        <h1 className="Ramillas text-4xl xl:mt-16 lg:mt-8 md:px-28 px-8 mt-8">
                            Investor's Login
                        </h1>
                        <p className="xl:mt-8 mt-4 md:w-[80%] w-full md:px-28 px-8">
                            As we continue our journey of innovation and
                            elegance, we invite you to be a part of our story.
                        </p>
                    </div>
                    <div className="lg:w-[45vw]">
                        <img
                            src="investor.png"
                            alt=""
                            className="lg:h-full h-[300px] w-full object-cover"
                        />
                    </div>
                </div>
                <div className="bg-[#f7f7f7] h-full lg:w-[92%] w-full lg:mt-0 mt-8 lg:py-0 py-8">
                    <div className="bg-white shadow-[0_0px_20px_-5px_rgba(0,0,0,0.3)] lg:w-[70%] w-[90%] lg:ml-28 lg:mx-none mx-auto h-full lg:-translate-y-[2vw] xl:-translate-y-[7vw] 2xl:-translate-y-[10vw] -translate-y-0 md:p-32 p-8">
                        <h1 className="Ramillas text-5xl text-center">Login</h1>
                        <p className="text-center mt-4 text-gray-500">
                            Login page as an Investor for everidoor
                        </p>
                        <form
                            action="https://formspree.io/f/mwkdqorn"
                            method="POST"
                            onSubmit={handleSubmit}
                            className="mt-8 text-sm"
                        >
                            <div className="mt-8 md:flex w-full">
                                <div className="w-full">
                                    <p>Full Name</p>
                                    <input
                                        type="text"
                                        name="Name"
                                        id="name"
                                        placeholder="Enter your full name"
                                        className="md:w-full w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mt-8">
                                <div className="md:mt-8 mt-4">
                                    <p>Phone Number</p>
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        name="Telephone"
                                        id="Telephone"
                                        value={value}
                                        onChange={setValue}
                                        className="border-b-2 py-1 w-full mt-4 focus:outline-none text-gray-600"
                                        defaultCountry="IN"
                                    />
                                </div>
                                <div className="w-full mt-8">
                                    <p>Location</p>
                                    <CountryDropdown
                                        value={country}
                                        onChange={(val) => setCountry(val)}
                                        classes="focus:outline-none w-full border-b-2 mt-4 py-1 text-gray-600"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="md:flex mt-8">
                                <div className="w-full">
                                    <p>E-Mail address</p>
                                    <input
                                        type="email"
                                        name="Email"
                                        id="email"
                                        placeholder="Enter your E-Mail Address"
                                        className="w-full py-1 mt-4 border-b-2 focus:outline-none text-gray-600"
                                        required
                                    />
                                    <ValidationError
                                        prefix="Email"
                                        field="email"
                                        errors={state.errors}
                                    />
                                </div>
                            </div>
                            <div className="mt-16 flex gap-4 items-center">
                                <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    className="h-4 w-4 rounded-sm cursor-pointer"
                                    required
                                />
                                <p className="cursor-pointer text-gray-500">
                                    I would like to recieve emails regarding any
                                    further updates on everidoor
                                </p>
                            </div>
                            <div className="w-full">
                                <button
                                    type="submit"
                                    className="py-4 px-16 hover:bg-[#6b2780] text-gray-500 hover:text-white border-2 border-gray-500 font-semibold text-sm mt-8 relative left-[50%] -translate-x-[50%]"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section>
                <div className="md:p-36 p-8 text-center text-lg">
                    As we continue our journey of innovation and elegance, we
                    invite you to be a part of our story. Whether you're a space
                    provider looking to transform spaces or an advertiser
                    seeking to captivate the elite, everidoor is your gateway to
                    a world where luxury knows no bounds.
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Investor;
