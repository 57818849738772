import React from 'react'

function CarouselsImg({item}) {
  return (
    <div className='inline-flex items-center justify-center'>
      <img src={item.src} alt="" className='caraousel-img object-contain '/>
    </div>
  )
}

export default CarouselsImg
