import React, { useState } from "react";
import { VscThreeBars } from "react-icons/vsc";
import logo from "./images/everidoor.jpg";
import face from "./images/64.png";
import wide from "./images/65.png";
import first from "./images/first.png";
import six from "./images/sixth.png";
import seven from "./images/seven.png";
import second from "./images/sec.png";
import third from "./images/third.png";
import eight from "./images/eight.png";
import fourth from "./images/four.png";
import fifth from "./images/five.png";
import Footer from "./Components/Footer";
import ExploreCard from "./Components/ExploreCard";
import Header from "./Components/Header";
import { Link } from "react-router-dom";
export const exploreWorlds = [
    {
        id: "1",
        imgUrl: first,
    },
    {
        id: "2",
        imgUrl: second,
    },
    {
        id: "3",
        imgUrl: third,
    },
    {
        id: "4",
        imgUrl: fourth,
    },
    {
        id: "5",
        imgUrl: fifth,
    },
    {
        id: "6",
        imgUrl: six,
    },
    {
        id: "7",
        imgUrl: seven,
    },
    {
        id: "8",
        imgUrl: eight,
    },
];

function Partner() {
    const [active, setActive] = useState("");
    return (
        <div>
            <Header />
            {/* Hero */}
            <section className="h-full border-b md:border-none  relative">
                <div className="text-black  text-center  py-10 ">
                    <h2 className="md:text-2xl text-md ramillas  font-light ">DISPLAY NETWORK</h2>
                    <h1 className="Ramillas md:text-6xl text-[44px] hidden md:block leading-[50px] mr-5 ml-5 mt-[15px] ">
                        Elevate Spaces, Illuminate Brands
                    </h1>
                    <h1 className="Ramillas md:text-6xl text-[44px] block md:hidden leading-[50px] mr-5 ml-5 mt-[15px] ">
                        Elevate Spaces,
                    </h1>
                    <h1 className="Ramillas md:text-6xl text-[40px] block md:hidden leading-[50px]  ">
                        Illuminate Brands
                    </h1>
                </div>
                {/* <div className="bg-[#340C42] h-[300px] w-full absolute bottom-0 -z-10"></div> */}
                <div className="md:mt-[70px] w-full pl-10 pr-10 pb-10 md:pl-5 md:pr-5 lg:px-64 flex md:flex-row justify-center items-center flex-col md:min-h-[50vh] min-h-[80vh] gap-2 md:gap-8 ">
                    {exploreWorlds.map((world, index) => (
                        <ExploreCard
                            key={world.id}
                            {...world}
                            index={index}
                            active={active}
                            handleClick={setActive}
                        />
                    ))}
                </div>
            </section>
            {/* Second */}
            <section className="h-full flex flex-col items-center border-b md:border-none justify-center md:gap-16 gap-4 py-10 md:py-32">
                <h1 className="Ramillas md:text-5xl text-[24px] text-[#000000]  text-center md:px-0 px-8" style={{ lineHeight: "1.4" }}>
                    Partner with Us to <br /> Redefine Luxury Exposure
                </h1>
                <div className="bg-transparent px-6 mb-8 md:hidden  cursor-pointer">
                    <Link to={"/Contact"}>
                        <p className="underline underline-offset-4 lg:text-[20px] text-[12px]  tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Join Our Network
                        </p>
                    </Link>
                </div>
                <p className="md:w-[70%] text-[14px] md:text-2xl  leading-[25px]  font-light text-justify  md:px-0 px-8 text-[#424242]">
                    At everidoor, we understand the value of curated environments. Our Luxury Display Network isn't just about
                    advertising; it's about transforming spaces into captivating experiences. Join us as a display provider and become a
                    catalyst for brands seeking to resonate with the elite.
                </p>
                <div className="bg-transparent px-6 hidden md:block  cursor-pointer">
                    <Link to={"/Contact"}>
                        <p className="underline underline-offset-4 lg:text-[20px] text-[12px]  tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Join Our Network
                        </p>
                    </Link>
                </div>
            </section>
            {/* Third */}
            <section className="h-full md:w-[95%] border-b md:border-none  md:flex items-center justify-end md:mt-[100px]">
                <div className="md:w-[60%] mt-10 md:px-10 px-8">
                    <h1 className="md:text-5xl hidden md:block Ramillas md:text-left text-center">
                        Your Space, Their Canvas
                    </h1>
                    <h1 className="md:text-5xl text-[32px] md:hidden block Ramillas md:text-left text-center">
                        Your Space,
                    </h1>
                    <h1 className="md:text-5xl text-[32px] md:hidden  block Ramillas md:text-left text-center">
                        Their Canvas
                    </h1>
                    <Link to={"/Contact"}>
                        <p className="underline block md:hidden underline-offset-4 lg:text-[20px] text-[12px] mt-4  md:mt-16  tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Become a partner
                        </p>
                    </Link>
                    <div className="w-fit flex md:hidden justify-end md:mr-12 p-10 md:p-0 md:mt-0 mt-5">
                        <img src={face} width={"422px"} height={"538px"} alt="" />
                    </div>

                    <p className="md:text-[20px] text-[14px] lg:w-[689px] pb-10 md:pb-0 md:p-0 md:mt-20 font-light tracking-wide text-justify  text-[#424242]">
                        By hosting our state-of-the-art digital screens, you're
                        not just offering ad <br /> space; you're curating storytelling
                        platforms and opening the door to <br /> additional revenue
                        streams. With our technology, you'll have the power to <br />
                        remotely manage and curate the content displayed,
                        aligning perfectly with <br /> your space's ambiance and your
                        guests' expectations.
                    </p>
                    <Link to={"/Contact"}>
                        <p className="underline hidden md:block underline-offset-4 lg:text-[20px] text-[20px] mt-8 md:mt-16  tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Become a partner
                        </p>
                    </Link>
                </div>
                <div className="w-fit hidden md:flex justify-end md:mr-12 p-4 md:p-0 md:mt-0 mt-8">
                    <img src={face} width={"422px"} height={"538px"} alt="" />
                </div>
            </section>
            {/* Fourth */}
            <section className="md:pt-56 mt-10 md:mt-0 md:pb-60">
                <h1 className="  Ramillas  text-center">
                    <span className="text-[#340C4299] md:text-4xl text-[20px] ">Seamless Integration,</span>  <br /> <span className="md:text-[40px] text-[26px]"> Unparalleled Exposure</span>
                </h1>
                <div className="underline underline-offset-4 flex md:hidden items-center justify-center lg:text-[20px] text-[12px] py-5 md:py-0 md:mt-16  tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                    <Link
                        to={"/Contact"}

                    >
                        Become a Partner
                    </Link>
                </div>
                <div className=" items-center  flex md:hidden p-4  px-6  justify-center w-[100%]">
                    <img src={wide} alt="" className="md:w-[75%] md:mt-20" />
                </div>
                <p className="md:w-[60%] p-4 px-6 text-[14px] mx-auto  mb-3 md:mb-0 font-light text-justify md:text-center md:text-[20px] text-[#424242]">
                    At everidoor, we understand the value of curated environments. Our Luxury Display Network isn't just about advertising; it's about transforming spaces into captivating experiences. Join us as a display provider and become a catalyst for brands seeking to resonate with the elite.
                </p>
                <div className=" items-center hidden md:flex p-4 justify-center w-[100%]">
                    <img src={wide} alt="" className="md:w-[75%] md:mt-20" />
                </div>
                <div className="underline underline-offset-4 hidden md:flex items-center justify-center lg:text-[20px] text-[16px] py-5 md:py-0 md:mt-16  tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                    <Link
                        to={"/Contact"}

                    >
                        Become a Partner
                    </Link>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Partner;