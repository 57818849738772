import React, { useState } from "react";
import { VscThreeBars } from "react-icons/vsc";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import logo from "../../images/edoorwhite.png";
import { motion } from "framer-motion";

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};
const Path = (props) => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="#fff"
        strokeLinecap="round"
        {...props}
    />
);

function OutlookHeader() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="w-full p-4 bg-[#340c42]/70 fixed z-50">
            <div className="flex justify-between items-center text-sm md:mt-0 mt-4">
                <Link to={"/"} className="w-fit">
                    <div className="flex md:h-[28px] h-[28px] w-fit ">
                        <img alt="everidoor" src={logo} />
                    </div>
                </Link>
                <div className="flex gap-4 items-start">
                    <Link to={"/Contact"}>
                        <div className="border-[#FF4D00] hidden md:block border-opacity-25 border-2 md:px-4 py-1 px-1 md:text-md text-[12px] font-thin text-white hover:bg-[#FF4D00] hover:text-white cursor-pointer transition-all">
                            JOIN OUR NETWORK
                        </div>
                    </Link>
                    <motion.nav
                        initial={false}
                        animate={isOpen ? "open" : "closed"}
                        className=""
                    >
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="mt-[2px]"
                        >
                            <svg width="30" height="30" viewBox="0 0 22 22">
                                <Path
                                    variants={{
                                        closed: { d: "M 2 2.5 L 20 2.5" },
                                        open: { d: "M 3 16.5 L 17 2.5" },
                                    }}
                                />
                                <Path
                                    d="M 2 9.423 L 20 9.423"
                                    variants={{
                                        closed: { opacity: 1 },
                                        open: { opacity: 0 },
                                    }}
                                    transition={{ duration: 0.1 }}
                                />
                                <Path
                                    variants={{
                                        closed: { d: "M 2 16.346 L 20 16.346" },
                                        open: { d: "M 3 2.5 L 17 16.346" },
                                    }}
                                />
                            </svg>
                        </button>
                        <motion.ul
                            variants={{
                                open: {
                                    clipPath: "inset(0% 30% 0% 0% round 10px)",
                                    transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.7,
                                        delayChildren: 0.3,
                                        staggerChildren: 0.05,
                                    },
                                },
                                closed: {
                                    clipPath:
                                        "inset(10% 50% 90% 50% round 10px)",
                                    transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.3,
                                    },
                                },
                            }}
                            style={{ pointerEvents: isOpen ? "auto" : "none" }}
                            className="bg-white border text-black fixed -right-8 top-20 pr-20 py-4 pl-6 text-lg"
                        >
                            <motion.li variants={itemVariants} className="mb-1">
                                <Link
                                    to={"/"}
                                    className="hover:text-[#f94b02] hover:underline transition-all duration-500"
                                >
                                    Home
                                </Link>
                            </motion.li>
                            <motion.li variants={itemVariants} className="mb-1">
                                <Link
                                    to={"/About"}
                                    className="hover:text-[#f94b02] hover:underline transition-all duration-500"
                                >
                                    About
                                </Link>
                            </motion.li>
                            <motion.li variants={itemVariants} className="mb-1">
                                <Link
                                    to={"/Advertiser"}
                                    className="hover:text-[#f94b02] hover:underline transition-all duration-500"
                                >
                                    Advertiser
                                </Link>
                            </motion.li>
                            <motion.li variants={itemVariants} className="mb-1">
                                <Link
                                    to={"/Partner"}
                                    className="hover:text-[#f94b02] hover:underline transition-all duration-500"
                                >
                                    Partner
                                </Link>
                            </motion.li>
                            <motion.li variants={itemVariants} className="mb-1">
                                <Link
                                    to={"/"}
                                    className="hover:text-[#f94b02] hover:underline transition-all duration-500"
                                >
                                    Locations
                                </Link>
                            </motion.li>
                            <motion.li variants={itemVariants} className="mb-1">
                                <Link
                                    to={"/Contact"}
                                    className="hover:text-[#f94b02] hover:underline transition-all duration-500"
                                >
                                    Contact
                                </Link>
                            </motion.li>
                        </motion.ul>
                    </motion.nav>
                </div>
            </div>
        </div>
    );
}

export default OutlookHeader;
