import React from 'react'
import Header from '../Components/Header'
import location1 from '../images/locations1.png'
import MonarchAir from '../images/MonarchAir.png'
import privateplanehero from "../images/privateplanehero.png"
import gyminterior from "../images/gyminterior.png"
import pexelsriccardo from "../images/pexelsriccardo.png"
import golf from '../images/pexels-tyler-hendy-54123 2.png'
import cotton from '../images/pexels-cottonbro-studio-6474588 2.png'
import cars from '../images/pexels-photo-274108 1.png'
import bgImg from '../images/everidoor (3) 1.png'
import Footer from '../Components/Footer'

function Locations() {
    return (
        <>
            <Header />
            <div className='md:w-[100%]   md:h-[5200px] h-[3250px]  bg-[#F5F5F5]'>
                <section>
                    <div className='md:w-[100%] flex flex-col  justify-center items-center md:h-[136px]'>
                        <p className='text-[#340C42] pt-20 uppercase  md:text-[94px] text-[32px] Ramillas '>
                            Locations
                        </p>
                        <div className='md:border-t   w-[40%] border-[#340C42]'>
                        </div>
                    </div>
                    {/* <div className='flex items-center md:mt-28 mt-8 justify-center'>
                        <img src={location1} className='md:h-[669px] md:w-[1050px] px-8' alt="everidoor" />
                    </div> */}
                    <div className='sm:hidden border-b mt-8 mx-auto w-[70%] mb-10'>
                    </div>
                </section>
                <section>
                    <div className='lg:w-[100%] lg:mt-36  md:pt-[100px]  flex lg:items-center pt-5 justify-center lg:justify-start lg:h-[460px] h-[80px]  '>
                        <div className='md:w-[55%]  h-fit flex lg:mb-20 lg:pl-28   lg:mt-10 md:items-start  justify-start md:justify-center '>
                            <p className='text-[#181818]   Ramillas md:text-[64px]  text-[30px] font-semibold'>
                                Private Jet Terminals
                            </p>
                        </div>


                    </div>

                    <div className=' lg:h-[1840px]    lg:pl-24   w-[100%]'>
                        <div className='flex justify-center bg-white items-center  md:w-[98%] lg:float-none float-right lg:ml-0 w-[90%] h-fit relative z-0'>
                            <div className='bg-white p-3 md:w-[100%] w-[95%] h-[1380px] lg:bottom-32 relative z-0'>
                                <div className='md:h-[300px]  mb-8 md:flex md:w-[100%]'>
                                    <div className='lg:flex flex-col hidden  justify-center md:pl-40  lg:pl-36 md:pb-12 w-[100%] md:w-[70%] text-justify lg:text-left '>
                                        <p className='text-[18px] font-light leading-6'>At the nexus of exclusivity, the private</p>
                                        <p className='text-[18px] font-light leading-6'>jet terminal stands as a lavish sanctuary</p>
                                        <p className='text-[18px] font-light leading-6'>where the elite embark on seamless</p>
                                        <p className='text-[18px] font-light leading-6'>journeys, transcending the ordinary and</p>
                                        <p className='text-[18px] font-light leading-6'>embracing a realm.</p>
                                    </div>
                                    <div className='md:w-[40%]  lg:right-[155px] lg:bottom-[250px] relative z-0'>
                                        <img src={MonarchAir} alt="everidoor" className='md:h-[423px] ml-16 lg:ml-0 lg:w-[508px] h-[194px] ' />
                                    </div>
                                    <div className=' lg:pt-4 lg:pr-4 bg-white absolute  top-36  lg:left-[45%] md:w-[352px] w-[162px] md:h-[277px]   md:block z-0 '>
                                        <img src={privateplanehero} alt="everidoor" className='md:h-[235px] absolute z-0 md:w-[335px] w-[162px] h-[108px]' />
                                    </div>
                                    <div className=''>
                                        <p className='text-[14px] sm:hidden text-justify p-1 mt-24 border-l lg:mt-0 font-light tracking-widest'>
                                            At the nexus of exclusivity, the private jet terminal stands as a lavish sanctuary where the elite embark on seamless journeys, transcending the ordinary and embracing a realm.
                                        </p>
                                    </div>
                                    <hr className='mt-8 lg:hidden ' />
                                </div>
                                <div className=' md:left-[337px] relative lg:top-[100px] flex items-center flex-col  lg:w-[100%] '>
                                    <div className=' lg:h-[100px] h-[70px]  xl:w-[70%] lg:w-[80%]'>
                                        <p className='md:text-[64px] text-[34px] relative text-center lg:text-start lg:right-[245px]  md:font-normal font-semibold Ramillas text-[#181818] '>
                                            Gymnasiums
                                        </p>
                                    </div>
                                    <div className='flex md:bg-[#F5F5F5] w-[100%]  border-b lg:border-none   md:h-[495px] '>
                                        <div className=' md:w-[100%] w-[100%]   lg:pr-2 lg:mr-2 flex flex-col gap-2  md:gap-0 md:flex-row justify-start items-center   md:bg-[#F5F5F5] bg-white '>
                                            <img src={gyminterior} alt="everidoor" className='md:w-[499px] lg:relative lg:right-[110px]  z-0 md:h-[363px] w-[90%] h-[204px] ' />
                                            <div className=' lg:flex flex-col hidden tracking-widest  h-auto md:w-auto w-[100%]  relative z-10 md:text-justify text-start justify-center '>
                                                <p className='text-[18px] font-light '>Within the hallowed halls of fitness</p>
                                                <p className='text-[18px] font-light '>refinement, gymnasiums transform</p>
                                                <p className='text-[18px] font-light '>into opulent sanctuaries, where the </p>
                                                <p className='text-[18px] font-light '>pursuit of physical excellence</p>
                                                <p className='text-[18px] font-light '>interwines with a tapestry of</p>
                                                <p className='text-[18px] font-light '>indulgence, elevating the concept of</p>
                                                <p className='text-[18px] font-light '>wellness to a realm of sheer luxury.</p>
                                            </div>
                                            <div className='p-3 sm:hidden mb-3'>
                                                <div className='border-l p-2'>
                                                    <p className='text-[14px]  sm:hidden font-light text-justify leading-6'>
                                                        Within the hallowed halls of fitness refinement, gymnasiums transform into opulent sanctuaries, where the pursuit of physical excellence intertwines with a tapestry of indulgence, elevating the concept of wellness to a realm of sheer luxury.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='md:left-[250px]  relative lg:h-[550px] h-[1250]   md:mt-0 w-[100%] md:w-[1272px] '>
                                    <div className='w-[76%] xl:w-[90%] flex bg-white lg:mt-52 mt-12 gap-5  lg:gap-72 h-[290px]   lg:ml-[225px] flex-col items-end  mx-3  md:h-[1000px]  '>
                                        <div className=' relative  md:top-[220px]  md:right-[200px] lg:pt-10  w-[380px] lg:pl-20 pl-44 h-[400px]   md:w-[1256px]  lg:float-left '>
                                            <img src={pexelsriccardo} alt="everidoor" className='md:w-[1074px] w-[380px] h-[100px] lg:h-[400px] ' />
                                        </div>
                                        <div className='relative  md:right-80 w-[300px] ml-20 lg:ml-0 lg:pt-3  lg:w-[100%] h-[300px]  lg:float-left '>
                                            <span className='Ramillas md:text-[64px]  ml-24  lg:ml-20  text-[34px] text-[#181818] md:font-normal font-semibold md:mx-0 lg:mx-16 '>
                                                Art Galleries
                                            </span>
                                            <div className=' hidden lg:block w-[100%] lg:text-left pl-20 pb-10  h-[100%]'>
                                                <p className='text-[18px] leading-6 tracking-widest  font-light'>
                                                    In the gilded realms of artistic splendor, art
                                                </p>
                                                <p className='text-[18px]  leading-6 tracking-widest font-light'>
                                                    galleries emerge as havens where creativity
                                                </p>
                                                <p className='text-[18px] leading-6 tracking-widest  font-light'>
                                                    converges with sophistication,&nbsp; inviting
                                                </p>
                                                <p className='text-[18px] leading-6 tracking-widest  font-light'>
                                                    patrons into a world where every stroke of
                                                </p>
                                                <p className='text-[18px] leading-6 tracking-widest  font-light'>
                                                    genius is adorned in luxury's finest.
                                                </p>
                                            </div>
                                            <div className='block lg:hidden bg-white text-justify mx-auto w-[310px] ml-16     text-[14px] leading-6 tracking-widest font-light'>
                                                <div className='border-l p-3'>
                                                    In the gilded realms of artistic splendor, art galleries emerge as havens where creativity converges with sophistication, inviting patrons into a world where every stroke of genius is adorned in luxury's finest.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section>
                    <div className=' w-[100%]  h-[2300px]'>
                        <div className='    float-right  lg:float-none lg:mt-40 lg:pr-[14px] xl:w-[93.6%] lg:w-[92.8%]'>
                            <div className='bg-white md:flex float-right  lg:float-none border-t lg:border-none pt-10 lg:pt-0 lg:ml-24 z-10 lg:w-[100%] h-full pb-6 md:h-[1000px] w-[90%]  relative '>
                                <div className='z-10 lg:pt-20 lg:ml-32  bg-white md:w-[65%] xl:w-[59%]  md:h-[67%] w-[100%] h-[75%] '>
                                    <img src={cars} className='md:h-[575px] md:w-[962px]  w-[241px] h-[169px] z-10 p-4' alt="everidoor" />
                                </div>
                                <div className=''>
                                    <div className='text-[18px] hidden  text-left  lg:block lg:mx-8 md:pt-20 tracking-widest py-0'>
                                        <p className='text-[18px] font-light leading-6'>In the realm where</p>
                                        <p className='text-[18px] font-light leading-6'>athletic prowess meets</p>
                                        <p className='text-[18px] font-light leading-6'>lavish indulgence,</p>
                                        <p className='text-[18px] font-light leading-6'>premium sports clubs</p>
                                        <p className='text-[18px] font-light leading-6'>emerge as exclusive</p>
                                        <p className='text-[18px] font-light leading-6'>sanctuaries where the</p>
                                        <p className='text-[18px] font-light leading-6'>thrill of competition</p>
                                        <p className='text-[18px] font-light leading-6'>interwines seamlessly</p>
                                        <p className='text-[18px] font-light leading-6'>with the lap of luxury,</p>
                                        <p className='text-[18px] font-light leading-6'>offering a privileged</p>
                                        <p className='text-[18px] font-light leading-6'>few an arena for both</p>
                                        <p className='text-[18px] font-light leading-6'>victory and respite.</p>
                                    </div>
                                    <div className='absolute md:bottom-[245px] bottom-[255px] md:right-20 xl:right-24 right-10 md:p-0 p-3  z-0 bg-white border-none'>
                                        <img src={golf} className='md:h-[350px] h-[72px] w-[108px] md:w-[531px]  z-0 ' alt="everidoor" />
                                    </div>
                                    <div className='absolute md:left-48 left-6 md:bottom-[250px] bottom-[285px] Ramillas font-semibold'>
                                        <p className='md:text-[64px] text-[20px] text-[#181818]'>Premium Clubs</p>
                                    </div>
                                    <div className='text-[14px] lg:hidden pl-2  h-[200px] mt-20 font-light  tracking-widest text-justify '>
                                        <div className='border-l p-2'>
                                            In the realm where athletic prowess meets lavish indulgence, premium sports clubs emerge as exclusive sanctuaries where the thrill of competition intertwines seamlessly with the lap of luxury, offering a privileged few an arena for both victory and respite.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white lg:mx-24 lg:float-none float-right md:px-24 border-t lg:border-none  md:w-[1455px] w-[90%] xl:w-[92.8%] h-[600px] lg:h-[1350px]'>
                            <div className='flex relative bg-white'>
                                <div className='bg-[#F5F5F5] md:w-[456px]  h-[600px] lg:h-[850px] z-0'>
                                </div>
                                <div className='my-10 left-8  bg-white md:left-96  z-40 absolute md:top-0 top-20 md:w-[70%]  ' >
                                    <img src={cotton} alt='studio' className='md:w-[597px] w-[295px] md:h-[413px] h-[194px]' />
                                </div>
                                <div className='absolute md:w-[60%]  text-center lg:text-start  md:top-[520px] top-[60px] lg:left-[510px]'>
                                    <h1 className='md:w-[376px] h-[73px] md:text-[64px] text-[24px] md:font-normal font-semibold Ramillas'>
                                        Fine Hotels
                                    </h1>
                                    <div className='md:my-6 hidden tracking-normal lg:block md:leading-normal text-left font-light leading-4 text-[18px] md:w-[90%] w-[80%] my-56 md:px-0 px-4 '>
                                        <p className='leading-6   text-[18px] font-light'>Within the sumptuous embrace of fine hotels, elegance becomes an art form, and</p>
                                        <p className='leading-6   text-[18px] font-light'>every moment unfolds in a tapestry of luxury. These sanctuaries of opulence</p>
                                        <p className='leading-6   text-[18px]  font-light'>redefine hospitality, offering a haven where impeccable service,exquisite design,  </p>
                                        <p className='leading-6   text-[18px] font-light'>and indulgent comfort converge to create an experience that transcends </p>
                                        <p className='leading-6   text-[18px] font-light'> accommodation and becomes a symphony of refined living.</p>
                                    </div>
                                    <div className='block sm:hidden text-[14px] p-4 font-normal my-52 text-justify'>
                                        <p className='border-l pl-2 tracking-widest font-light'>
                                            Within the sumptuous embrace of fine hotels, elegance becomes an art form, and every moment unfolds in a tapestry of luxury. These sanctuaries of opulence redefine hospitality, offering a haven where impeccable service, exquisite design, and indulgent comfort converge to create an experience that transcends accommodation and becomes a symphony of refined living.
                                        </p>

                                    </div>
                                </div>

                            </div>
                            <div className='w-[100%] h-[370px] border-t lg:border-none bg-white flex items-center justify-center lg:mt-32'>
                                <div className='md:mb-8 mb-12  '>
                                    <img src={bgImg} alt='bgImg' className='md:w-[208px] w-[162px] md:h-[295px] h-[200px]' />
                                </div>
                                <div className='absolute text-white md:w-[380px]'>
                                    <p className='md:text-[64px] text-[42px] text-[#000000] Ramillas tracking-wide md:mx-0 lg:mx-2'>
                                        LO<span className='text-white'>CATIO</span>NS
                                    </p>
                                    <div className='flex items-center flex-col justify-center'>
                                        <p className=''>The kind of locations </p>
                                        <p className=''>that we cater</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    )
}

export default Locations
