import React, { useEffect, useState } from 'react';
import CarouselsImg from './CarouselsImg';

function Carousels() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const items = [
    {
      index: 0,
      src: 'https://everidoor.s3.ap-south-1.amazonaws.com/Official_Website_Images/wowmolog_52358_Luxury_resort_location_with_a_2_or_3_people_arou_daad6d77-5987-4b91-bd78-4db37c3ee635+1.png'
    },
    {
      index: 1,
      src: 'https://everidoor.s3.ap-south-1.amazonaws.com/Official_Website_Images/wowmolog_52358_Luxury_resort_in_a_city_location_with_a_2_or_3_p_72d95127-86c1-4fce-b1f9-0553abf7f994+1.png'
    },
    {
      index: 2,
      src: 'https://everidoor.s3.ap-south-1.amazonaws.com/Official_Website_Images/wowmolog_52358_back_profile_of_a_successful_young_lady_with_pre_876d8555-ac0e-43a4-8c99-bd0084335bdb+1.png'
    },
    {
      index: 3,
      src: 'https://everidoor.s3.ap-south-1.amazonaws.com/Official_Website_Images/wowmolog_52358_luxury_resort_location_with_a_2_or_3_people_arou_5bcf9357-592a-462e-96a4-44c3cb1cf578.png'
    },
    {
      index: 4,
      src: 'https://everidoor.s3.ap-south-1.amazonaws.com/Official_Website_Images/wowmolog_52358_Luxury_resort_in_a_city_location_with_a_2_or_3_p_9186dbee-889f-4d69-b3aa-d60c996a824d-2+1.png'
    }
  ];
  useEffect(() => {
    const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    }, 4000); // Change slides every 4 seconds

    return () => clearInterval(interval);
}, []);
  const updateIndex = (newIndex) => {
    let updatedIndex;
    if (newIndex < 0) {
      updatedIndex = items.length - 1; // Move to the last item if going beyond the first
    } else if (newIndex >= items.length) {
      updatedIndex = 0; // Move to the first item if going beyond the last
    } else {
      updatedIndex = newIndex; // Stay within the bounds of the items array
    }
    setActiveIndex(updatedIndex);
  };
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
};

const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const difference = touchStartX - touchEndX;

    if (Math.abs(difference) > 50) { // minimum swipe distance
        if (difference > 0) {
            updateIndex(activeIndex+1); // Swipe left, go to next image
        } else {
            updateIndex(activeIndex-1); // Swipe right, go to previous image
        }
    }
};
  return (
    <div className='carousel-container relative'>
    <button className='absolute md:-left-24 -translate-y-1/4 z-90 hidden lg:block' style={{marginTop: '25%'}} onClick={() => { updateIndex(activeIndex - 1); }}>
        <img src="arrow (2).svg" className='' alt="" />
      </button>
      <button className='absolute md:-right-24 -translate-y-1/4 z-90 hidden lg:block' style={{marginTop: '25%'}} onClick={() => { updateIndex(activeIndex + 1); }}>
        <img src="arrow (1).svg" className='' alt="" />
      </button>
    <div className='carousel' onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className='inner ' style={{ transform: `translate(-${activeIndex * 100}%)` }}>
        {items.map((item, index) => {
          return <CarouselsImg key={index} item={item} />;
        })}
      </div>
        <div className='indicators relative justify-center bottom-8 md:bottom-10 lg:bottom-16 left-2/4 z-50 flex -translate-x-2/4 gap-2'>
          {items.map((item, index) => {
            return (
              <button key={index} className="indicator-buttons bg-none border-none cursor-pointer" onClick={() => { updateIndex(index); }}>
                <span className={`block h-3 w-3 cursor-pointer rounded-full transition-colors ${activeIndex === index ? "bg-[#F0D77A]" : "bg-transparent border-2 border-[#F0D77A]"}`}></span>
              </button>
            );
          })}
        </div>
      </div>
      </div>
  );
}

export default Carousels;
