import React, { useState } from 'react'
import html2pdf from 'html2pdf.js';


function LetterOfIndtent() {
  const [client, setClient] = useState('')
  const [pack, setPack] = useState('')
  const [amount, setAmount] = useState('')
  const [duration, setDuration] = useState('')
  const [content, setContent] = useState('')
  const [brand, setBrand] = useState('')
  const [acknowledge, setAcknowledge] = useState('')
  const handlePrintAndPDF = () => {
    const element = document.getElementById('letterofintent'); // Select the entire body of the webpage
    const options = {
        margin: 0,
        filename: 'Letter_Of_Intent.pdf', // Optional: Set the filename of the PDF
        image: { type: 'jpeg', quality: 0.98 }, // Optional: Set the image quality
        html2canvas: { scale: 2, windowWidth: 1, height: 1200 }, // Optional: Set the scale factor of the HTML2Canvas rendering
        jsPDF: { unit: 'mm', format: [320,200], orientation: 'portrait' }, // Optional: Set the format and orientation of the PDF
      };

    // Use html2pdf library to generate the PDF
    html2pdf().from(element).set(options).save();
};

  const data = {
    client, pack, amount, duration, content, brand, acknowledge
  }
  return (
    <div className='relative flex flex-col'>
    <div id='letterofintent' className='overflow-hidden text-sm xl:text-lg flex flex-col border-2 xl:border-4 border-[#ffef88] xl:p-12 xl:m-6 m-2 p-4 relative'>
    <img src="backgroundloitop.png" className='absolute z-[-1] opacity-40 md:-top-[5%] -top-[1.5%] right-[0.1%]' alt="" />
      <header className='flex lg:w-full flex-row justify-between border-b-2 xl:border-b-4 border-[#ffef88]'>
      <h1 className='xl:mt-24 mt-14 xl:text-5xl text-2xl text-[#b19902] mb-2 xl:mb-4'>Letter of Intent</h1>
      <div className="logo">
        <img src="everidoorlogo.svg" alt="" className='xl:w-32 w-24 -mt-8 xl:-mt-6'/>
      </div>
      </header>
      <main className='flex flex-col gap-2 xl:gap-12'>
      <p className='flex flex-col gap-4 xl:gap-6 my-12 xl:my-28'>
      To, <br/>
      M/s Everidoor Private Limited <br/>
      813, Udyog Vihar, Phase V<br/>
      Gurugram, Haryana - 122016
      <span>Dear Everidoor Team,</span>
      <span>Subject: Intent to Purchase Advertising Space with Everidoor's Luxury Display Network</span>
      <span className='text-justify'>
        We, <input type="text" value={client} onChange={(e)=>setClient(e.target.value)} className='form-in mx-2 w-[60%]'/> , are excited to express our intention to engage with 
        Everidoor for the purpose of displaying our advertisements on your Luxury Display Network. We believe this strategic 
        placement will significantly contribute to our brand's visibility and resonance with an elite clientele.
      </span>
      <span className='text-justify'>
      Based on the information provided in your advertising solutions portfolio, we are interested in pursuing the following package.<br/>
      <div className="radio flex flex-wrap gap-2 mt-2">
      Select one:
        <input type="radio" id='quaterly' onClick={()=>setPack('Quaterly')} name='button'/>
        <label htmlFor="quaterly">Quaterly</label>/
        <input type="radio" id='halfyearly' onClick={()=>setPack('Half Yearly')} name='button'/>
        <label htmlFor="halfyearly">Half Yearly</label>/
        <input type="radio" id='annual' onClick={()=>setPack('Annual')} name='button'/>
        <label htmlFor="annual">Annual</label>/
        <input type="radio" id='exclusive' onClick={()=>setPack('Annual Exclusive Package')} name='button'/>
        <label htmlFor="exclusive">Annual Exclusive Package</label>
      </div>
      </span>
      <div className='-mt-4'>
      Selected Package : <input type='text' value={pack} onChange={(e)=>setPack(e.target.value)} className='form-in w-40 xl:w-64'/><br/> 
      Investment Amount : <input type='text' value={amount} onChange={(e)=>setAmount(e.target.value)} className='form-in w-32 xl:w-64'/><br/>
      Duration : <input type='text' value={duration} onChange={(e)=>setDuration(e.target.value)} className='form-in w-32 xl:w-64'/><br/>
      Content : <input type='text' value={content} onChange={(e)=>setContent(e.target.value)} className='form-in w-32 xl:w-64'/><br/>
      </div>
      <span>
      We understand that this Letter of Intent is not legally binding and that the terms and details will be further defined in a formal 
      agreement. However, we are ready to proceed with the next steps as outlined by your process.
      </span>
      <span>
      Next Steps:
        <ul className='list-disc p-2 xl:p-4'>
        <li>Finalize the content and specific screen locations for the advertisement.</li>
        <li>Determine the schedule for advertisement display.</li>
        <li>Draft and sign a formal agreement that will outline all terms and conditions of this engagement.</li>
        </ul>
      </span>
      <span>
      We look forward to a productive partnership that will enhance our brand's presence and align with the prestigious 
      environment that Everidoor's network promises.
      </span>
      <span>
      Kindly acknowledge receipt of this Letter of Intent and provide further instructions to proceed with this engagement.
      </span>
      <span>
      For Brand's Name : <input type="text" value={brand} onChange={(e)=>setBrand(e.target.value)} className='form-in w-32 mx-2 xl:w-64'/>
      </span>
      <span>
      Acknowledged by Everidoor : <input type='text' value={acknowledge} onChange={(e)=>setAcknowledge(e.target.value)} className='form-in w-32 mx-2 xl:w-64'/>
      </span>
      </p>
      <h2 className='text-[#b19902] relative flex justify-center xl:text-2xl'>
      World's Most Exclusive Display Network
      </h2>
      </main>
      <img src="backgroundloi.png" className='z-[-1] right-[0.1%] absolute -bottom-[3%] md:top-[70.5%] md:right-[0.1%] opacity-40' alt="" />
    </div>
    <button id="printButton" className="relative bg-green-500 hover:bg-green-700 text-white font-bold flex justify-center mx-auto py-2 px-4 my-4 rounded" onClick={handlePrintAndPDF}>Save & PDF</button>
    </div>
  )
}

export default LetterOfIndtent
