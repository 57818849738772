import React, { useState } from "react";
import Header from "./Components/Header";
import { CountryDropdown } from "react-country-region-selector";
import Footer from "./Components/Footer";
import { useForm, ValidationError } from "@formspree/react";

function Subscribe() {
    const [country, setCountry] = useState("");
    const [state, handleSubmit] = useForm("mzblvrjb");
    if (state.succeeded) {
        return (
            <section>
                <Header />
                <div className="flex items-center justify-center">
                    <p className="text-lg">
                        Form submitted with flair! Stay tuned for an enchanting
                        message from our team, dancing your way before you know
                        it!
                    </p>
                </div>
            </section>
        );
    }
    return (
        <div>
            <Header />
            <section className="bg-[#f7f7f7]">
                <div className="flex bg-[#f7f7f7] justify-between lg:flex-row flex-col-reverse md:mt-0 mt-20">
                    <div className="lg:mt-0 mt-8">
                        <h1 className="tracking-wider pt-3 text-[11px] md:px-28 px-8 font-semibold opacity-75">
                            <span className="text-gray-400">CONTACT</span> |
                            NEWSLETTER
                        </h1>
                        <h1 className="Ramillas text-[#340C42] text-5xl xl:mt-16 lg:mt-8 md:mx-28 mx-8 mt-8">
                            Subscribe to our newsletter
                        </h1>
                        <hr className="md:mx-28 mx-8 mt-4 text-[#000000] font-medium" />
                        <p className="xl:mt-8 mt-4 md:w-[80%] w-full text-[14px]  md:mx-28 mx-8">
                            As we continue our journey of innovation and elegance, we invite you to be a part of our story.
                        </p>
                    </div>
                    <div className="lg:w-[45vw]">
                        <img
                            src="Subscribe.png"
                            alt=""
                            className="lg:h-full h-[300px] w-full object-cover"
                        />
                    </div>
                </div>
                <div className="bg-[#f7f7f7] h-[1000px] lg:w-[92%] w-full lg:mt-0 mt-12 lg:py-0 py-8">
                    <div className="bg-white shadow-[0_0px_20px_-5px_rgba(0,0,0,0.3)] lg:w-[60%] w-[90%] lg:ml-28 lg:mx-none mx-auto h-full lg:-translate-y-[2vw] xl:-translate-y-[7vw] 2xl:-translate-y-[10vw] -translate-y-0 md:p-24 p-8">
                        <h1 className="Ramillas text-[#340C42] text-center w-full text-4xl">
                            Register
                        </h1>
                        <form
                            action="https://formspree.io/f/mzblvrjb"
                            method="POST"
                            onSubmit={handleSubmit}
                            className="mt-8 text-sm"
                        >
                            <div>
                                <p className="text-center w-full  text-lg text-[#555555]  font-light">Subscribe to our newsletter</p>

                            </div>
                            <div className="mt-8 md:flex items-center justify-center w-full">
                                <div className="w-[90%]">
                                    <p>Full Name</p>
                                    <input
                                        type="text"
                                        name="First Name"
                                        id="fname"
                                        placeholder="Enter your full Name"
                                        className="md:w-[90%] w-full py-1 mt-3 border-b-2 focus:outline-none text-gray-600"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="mt-8 md:flex items-center justify-center">
                                <div className="md:w-[90%] md:mt-0 mt-4">
                                    <p>Phone Number</p>
                                    <input
                                        type="text"
                                        name="Telephone"
                                        id="Telephone"
                                        placeholder="Enter your phone number"
                                        className="md:w-[90%] w-full py-1 mt-3 border-b-2 focus:outline-none text-gray-600"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="md:flex items-center justify-center mt-8">
                                <div className="md:w-[90%]">
                                    <p>Email</p>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter your E-Mail Address"
                                        className="md:w-[90%] w-full py-1 mt-3 border-b-2 focus:outline-none text-gray-600"
                                        required
                                    />
                                    <ValidationError
                                        prefix="Email"
                                        field="email"
                                        errors={state.errors}
                                    />
                                </div>
                            </div>
                            <div className="md:flex items-center justify-center mt-8">
                                <div className="md:w-[90%]">
                                    <p>Location</p>
                                    <select
                                        name="Salutation"
                                        id="Salutation"
                                        className="border-b-2 md:w-[90%] w-full mt-4 focus:outline-none py-1 text-gray-600"
                                        required
                                    >
                                        <option value="">Select your location</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mt-16 flex flex-col gap-4 items-start">
                                <div className="flex gap-2 items-center pl-10">
                                    <input
                                        type="checkbox"
                                        name="checkbox"
                                        id="checkbox"
                                        className="h-4 w-4 rounded-sm cursor-pointer "
                                        required
                                    />
                                    <p className=" font-light  cursor-pointer text-[#343434]">
                                        I would like to recieve emails regarding any further updates on everidoor
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center pl-10">
                                    <input
                                        type="checkbox"
                                        name="checkbox"
                                        id="checkbox"
                                        className="h-4 w-4 rounded-sm cursor-pointer "
                                        required
                                    />
                                    <p className="cursor-pointer font-light text-[#343434]">
                                        I have read, understood and agree to the <a className=" underline">Privacy Policy.</a>
                                    </p>
                                </div>
                            </div>
                            <div className="w-full mt-16 ">
                                <button
                                    type="submit"
                                    className="py-4 px-16 hover:bg-[#6b2780] text-gray-500 hover:text-white border-2 border-gray-500 font-light text-[20px] mt-8 relative left-[50%] -translate-x-[50%]"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="bg-[#f7f7f7]">
                <div className="w-[100%] lg:h-72 pb-6   md:pb-48 flex items-center justify-center">
                    <div className=" w-[90%] h-[50%]">
                        <p className="text-center text-[24px] text-[#343434]">
                            "As we continue our journey of innovation and elegance, we invite you to be a part of our story. Whether you're a space
                        </p>
                        <p className="text-center  text-[24px] text-[#343434]">
                            provider looking to transform spaces or an advertiser seeking to captivate the elite, everidoor is your gateway to a
                        </p>
                        <p className="text-center  text-[24px] text-[#343434]">
                            world where luxury knows no bounds".
                        </p>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Subscribe
