import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { BsSearch } from "react-icons/bs";

function Insights() {
    return (
        <div>
            <div>
                <Header />
                <div className="Ramillas md:px-20 px-8">
                    <div>
                        <h1 className="md:text-[3.5rem] text-[28px] text-center mt-20">
                            Insights
                        </h1>
                        <p className="md:text-[1rem] text-[16px] text-center mt-10 md:w-2/3 md:mx-auto mx-4">
                            Explore the world of luxury brand advertising
                            through our in-depth insights. Discover the latest
                            trends, consumer behavior patterns, and innovative
                            strategies that shape the realm of high-end
                            marketing. Gain valuable knowledge and inspiration
                            to elevate your luxury brand campaigns and stay
                            ahead in the ever-evolving landscape of luxury
                            advertising
                        </p>
                    </div>
                    <div className="flex md:flex-row flex-col justify-between py-16 md:px-16">
                        <div className="border-b-2 flex items-center md:w-1/3">
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Search..."
                                className="w-full py-2"
                            />
                            <BsSearch />
                        </div>
                        <select
                            name=""
                            id=""
                            className="border-b-2 py-2 pr-24 text-gray-400"
                        >
                            <option value="">Sort By</option>
                            <option value="">Latest</option>
                            <option value="">Oldest</option>
                            <option value="">Relevance</option>
                        </select>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-2 md:p-16 p-2 md:gap-20 gap-8 h-full">
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 58.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Cracking the Code: Luxury Consumer Behavior
                                Trends in 2023
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 59.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Beyond Aesthetics: The Psychology of Luxury
                                Branding
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 60.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Crisis Management in the Luxury Industry:
                                Navigating Challenges with Grace
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 61.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Cracking the Code: Luxury Consumer Behavior
                                Trends in 2023
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 62.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Beyond Aesthetics: The Psychology of Luxury
                                Branding
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 63.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Crisis Management in the Luxury Industry:
                                Navigating Challenges with Grace
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 64.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Cracking the Code: Luxury Consumer Behavior
                                Trends in 2023
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 65.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Beyond Aesthetics: The Psychology of Luxury
                                Branding
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                        <div className="cursor-pointer mx-auto">
                            <img
                                src="Insights/Rectangle 66.png"
                                alt=""
                                className="object-cover"
                            />
                            <p className="mt-4 md:text-[1rem] text-[16px]">
                                Crisis Management in the Luxury Industry:
                                Navigating Challenges with Grace
                            </p>
                            <p className="mt-4 text-gray-400">Sept 22, 2023</p>
                        </div>
                    </div>
                    <div className="flex gap-2 mx-auto w-fit mb-20 md:mt-0 mt-8">
                        <button type="button" className="text-gray-400">
                            {"< "}Previous
                        </button>
                        <p>1</p>
                        <button type="button" className="text-gray-400">
                            Next{" >"}
                        </button>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Insights;
