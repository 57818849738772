import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Route, BrowserRouter as Router, Routes, redirect } from "react-router-dom";
import "./fonts/TT Interphases Pro Variable.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));

const RedirectToWWW = () => {
    if (!window.location.host.startsWith('www')) {
        const newUrl = `${window.location.protocol}//www.${window.location.host}${window.location.pathname}`;
        return redirect(newUrl);
    }
    return null;
};
root.render(
    <Router>
        <Routes>
            <Route component={RedirectToWWW} />
        </Routes>
        <App />
    </Router>
);
