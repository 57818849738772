import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OutlookHeader from "./OutlookHeader";
import one from "../../images/Article Images/Article 4 (i).webp";

import OutlookFooter from "./OutlookFooter";
import { motion } from "framer-motion";

function ArticleFour() {
    const navigate = useNavigate();
    const [right, setRight] = useState(false);
    return (
        <motion.div
            initial={{ x: window.innerWidth }}
            animate={{
                x: 0,
                transition: { ease: "linear" },
            }}
            exit={
                right
                    ? { x: -window.innerWidth, transition: { duration: 0.4 } }
                    : { x: window.innerWidth, transition: { duration: 0.4 } }
            }
        >
            {/* Fixed Elements */}
            <button
                type="button"
                className="p-2 px-4 bg-[#340c42] text-white rounded-sm fixed bottom-16 right-16 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    setRight(true);
                    navigate("/Outlook2023-Five");
                }}
            >
                {">"}
            </button>
            <button
                type="button"
                className="text-white p-2 px-4 bg-[#340c42] rounded-sm fixed bottom-16 right-28 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    navigate("/Outlook2023-Three");
                }}
            >
                {"<"}
            </button>
            {/* Article */}
            <div>
                <div className="w-full h-[46vh] md:h-[60vh] overflow-hidden">
                    <div className="bg-black/50 w-full h-[100%] md:h-[60vh] relative z-10 flex items-center">
                        <motion.h1
                            className="text-xl md:text-5xl w-[80%] mx-auto Ramillas text-white text-center"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: { delay: 0.5 },
                            }}
                        >
                            "Dolce & Gabbana Ventures into Real Estate: Luxury Fashion Meets Property Market"
                        </motion.h1>
                    </div>
                    <motion.img
                        src={one}
                        alt=""
                        className="object-cover w-full relative  -top-[100%] h-full md:-top-[575px] lg:bottom-[150%]"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.5 },
                        }}
                    />
                </div>
                <motion.div
                    className="md:p-16 text-lg  md:flex"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.5 },
                    }}
                >
                    <div className="w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            Dolce & Gabbana, the iconic Italian fashion house led by Domenico Dolce and Stefano Gabbana, is embarking on a new chapter of diversification, evolving with each passing day. Following their forays into the realms of perfumery, the metaverse, and luxury homeware, D&G is now setting its sights on the property market.                        </p>
                        <p className="mt-4 text-justify">
                            From haute couture to hospitality, Dolce & Gabbana is poised to extend its reach into the real estate sector, spanning across Europe, America, and Asia.
                            Having delved deep into the art of perfumery, explored the metaverse with NFT collections, and ventured into the world of luxury homeware, the esteemed Italian fashion label is now setting its sights on acquiring a substantial real estate portfolio.
                        </p>
                    </div>
                    <div className=" w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            The brainchild of Domenico Dolce and Stefano Gabbana, the fashion powerhouse has unveiled ambitious plans to develop residential properties in the United States and Spain, alongside an exciting hospitality project set against the breathtaking backdrop of the Maldives.                        </p>
                        <p className="mt-4 text-justify">
                            A statement released on D&G's official Twitter account suggests that this strategic move is aimed at "reinforcing the lifestyle element inherent in its DNA," signaling the brand's commitment to offering an all-encompassing luxury experience that extends beyond fashion into the realms of living and leisure.                        </p>
                    </div>
                </motion.div>
                {/* <div className="mt-8 bg-slate-900 text-white p-8 md:p-16 text-lg md:flex">
                    <div>
                        <img src={one} alt="" />
                    </div>
                    <div className="mt-6 md:px-16">
                        <p className="text-justify">
                            Consumers seek niche sustainable brands that offer a
                            holistic experience rather than mere products. The
                            key to entering the luxury beauty arena lies in
                            aligning products with brand identity, ensuring
                            quality and relevance. Tom Ford's strategic sequence
                            of launching fragrance, makeup, and skincare
                            exemplifies this approach.
                        </p>
                        <p className="mt-4 text-justify">
                            Moreover, luxury beauty products are expected to
                            embody sustainability, efficacy, and uniqueness.
                            This is especially true for brands like Augustinus
                            Bader, Noble Panacea, La Mer, and La Prairie, which
                            invest heavily in research and development to offer
                            cutting-edge solutions.
                        </p>
                        <p className="mt-6 lg:mt-16 text-justify">
                            In a highly competitive market, differentiation is
                            crucial. Brands must leverage their unique
                            narratives and values to stand out. The growing
                            influence of platforms like TikTok in skincare
                            trends presents an opportunity for luxury brands to
                            connect with new audiences and boost visibility.
                        </p>
                        <p className="mt-6 lg:mt-4 text-justify">
                            To succeed, luxury brands must deliver an
                            exceptional customer experience. This encompasses
                            not only product quality but also personalized
                            customer service. As the industry evolves, luxury
                            brands that offer bespoke experiences and align with
                            customer values are poised for success.
                        </p>
                    </div>
                </div> */}
            </div>
            <OutlookFooter />
        </motion.div>
    );
}

export default ArticleFour;