import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OutlookHeader from "./OutlookHeader";
import one from "../../images/Article Images/Article 5 (i).jpg";

import OutlookFooter from "./OutlookFooter";
import { motion } from "framer-motion";

function ArticleFive() {
    const navigate = useNavigate();
    const [right, setRight] = useState(false);
    return (
        <motion.div
            initial={{ x: window.innerWidth }}
            animate={{
                x: 0,
                transition: { ease: "linear" },
            }}
            exit={
                right
                    ? { x: -window.innerWidth, transition: { duration: 0.4 } }
                    : { x: window.innerWidth, transition: { duration: 0.4 } }
            }
        >
            {/* Fixed Elements */}
            <button
                type="button"
                className="p-2 px-4 bg-[#340c42] text-white rounded-sm fixed bottom-16 right-16 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    setRight(true);
                    navigate("/Outlook2023-Six");
                }}
            >
                {">"}
            </button>
            <button
                type="button"
                className="text-white p-2 px-4 bg-[#340c42] rounded-sm fixed bottom-16 right-28 opacity-70 hover:opacity-100 z-30"
                onClick={() => {
                    navigate("/Outlook2023-Four");
                }}
            >
                {"<"}
            </button>
            {/* Article */}
            <div>
                <div className="w-full h-[46vh] md:h-[60vh] overflow-hidden">
                    <div className="bg-black/50 w-full h-[100%] md:h-[60vh] relative z-10 flex items-center">
                        <motion.h1
                            className="text-xl md:text-5xl w-[80%] mx-auto Ramillas text-white text-center"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: { delay: 0.5 },
                            }}
                        >
                            "Luxury Fashion Houses Enter Precious Jewelry Market to Tap into Growing Demand"
                        </motion.h1>
                    </div>
                    <motion.img
                        src={one}
                        alt=""
                        className="object-cover w-full relative  -top-[100%] h-full md:-top-[575px] lg:bottom-[150%]"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.5 },
                        }}
                    />
                </div>
                <motion.div
                    className="md:p-16 text-lg  md:flex"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.5 },
                    }}
                >
                    <div className="w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            In recent years, renowned luxury fashion houses such as Louis Vuitton, Prada, Gucci, Saint Laurent, and Balmain, along with Indian designers like Sabyasachi Mukherjee and Manish Malhotra, have strategically ventured into the precious jewelry market. This shift is driven by the sector's remarkable growth potential, with the global fine jewelry industry expected to reach $340-$360 billion in retail value by 2025. Branded jewelry, which currently comprises only 20% of this market, is predicted to grow at a compound annual growth rate (CAGR) of 8% to 12%, possibly reaching a 25-30% market share and generating $80 to $100 billion by 2025, according to a McKinsey report.                        </p>
                        <p className="mt-4 text-justify">
                            Louis Vuitton, for instance, identified fine jewelry as a high-growth category within its luxury business since launching its first line in 2001. Other established fashion houses have followed suit, leveraging their brand prestige to cater to this expanding market. Prada's debut collection, "Eternal Gold," launched in 2022, focuses on sustainability and targets younger consumers, utilizing 100% certified recycled gold. Gucci, Saint Laurent, and Balmain have also introduced fine jewelry lines, featuring ethically sourced materials and unique design elements.                        </p>
                    </div>
                    <div className=" w-full md:w-1/2 p-4">
                        <p className="text-justify">
                            In India, Sabyasachi Mukherjee's foray into wedding jewelry in 2017 and Manish Malhotra's recent launch of a high-jewelry collection demonstrate how even home-grown designers are capitalizing on this trend. These luxury fashion houses and designers are tapping into the lucrative world of precious jewelry, aligning with evolving consumer preferences and the industry's promising growth prospects.                        </p>
                        {/* <p className="mt-4 text-justify">
                            Of the top three French companies, Hermès has displayed the most remarkable growth, registering a remarkable 23% increase in sales during the first quarter of this year. The driving force behind LVMH's ascent is none other than its visionary CEO, Bernard Arnault. Collaborating with the CEOs of Louis Vuitton and Moët Hennessy in 1987, Arnault laid the foundation for the current corporate behemoth. As the founder and chairman of LVMH, Bernard Arnault presently holds the title of the world's wealthiest individual, a testament to his exceptional leadership and the enduring appeal of LVMH's luxury offerings.
                        </p> */}
                    </div>
                </motion.div>
                {/* <div className="mt-8 bg-slate-900 text-white p-8 md:p-16 text-lg md:flex">
                    <div>
                        <img src={one} alt="" />
                    </div>
                    <div className="mt-6 md:px-16">
                        <p className="text-justify">
                            Consumers seek niche sustainable brands that offer a
                            holistic experience rather than mere products. The
                            key to entering the luxury beauty arena lies in
                            aligning products with brand identity, ensuring
                            quality and relevance. Tom Ford's strategic sequence
                            of launching fragrance, makeup, and skincare
                            exemplifies this approach.
                        </p>
                        <p className="mt-4 text-justify">
                            Moreover, luxury beauty products are expected to
                            embody sustainability, efficacy, and uniqueness.
                            This is especially true for brands like Augustinus
                            Bader, Noble Panacea, La Mer, and La Prairie, which
                            invest heavily in research and development to offer
                            cutting-edge solutions.
                        </p>
                        <p className="mt-6 lg:mt-16 text-justify">
                            In a highly competitive market, differentiation is
                            crucial. Brands must leverage their unique
                            narratives and values to stand out. The growing
                            influence of platforms like TikTok in skincare
                            trends presents an opportunity for luxury brands to
                            connect with new audiences and boost visibility.
                        </p>
                        <p className="mt-6 lg:mt-4 text-justify">
                            To succeed, luxury brands must deliver an
                            exceptional customer experience. This encompasses
                            not only product quality but also personalized
                            customer service. As the industry evolves, luxury
                            brands that offer bespoke experiences and align with
                            customer values are poised for success.
                        </p>
                    </div>
                </div> */}
            </div>
            <OutlookFooter />
        </motion.div>
    );
}

export default ArticleFive;