import React from 'react'
import Header from '../src/Components/Header'
import Footer from './Components/Footer'

function Policy() {

    const menu = [
        {
            head: "1. Information We Collect: ",
            title: "   Personal Information:"
        },
        {
            content: "When you create an everidoor account, we may collect your name, email address, phone number, and other contact information.If you use payment services, we may collect payment information such as credit card details.We may collect information you provide when you contact our customer support or participate in surveys or promotions."
        },
        {
            title: " Usage Information:"
        },
        {
            content: "We collect data about how you use everidoor, including log files, device information, and usage patterns.We may use cookies and similar tracking technologies to enhance your experience and collect usage information."
        },
        {
            head: "2. How We Use Your Information: ",
            title: " Providing Services:"
        },
        {
            content: "We use your personal information to provide and improve our services, including customizing content and suggestions."
        },
        {
            title: "  Communication: "
        },
        {
            content: "We may send you transactional emails, service updates, and promotional communications based on your preferences."
        },
        {
            title: " Analytics and Research:"
        },
        {
            content: "We analyze user data to improve our services and conduct research to better understand user needs and trends."
        },
        {
            title: " Legal Compliance:"
        },
        {
            content: "We may use your information to comply with legal obligations, resolve disputes, and enforce our policies."
        },
        {
            head: "3. Data Sharing:",
            title: " Third Party Partners:"
        },
        {
            content: "We may share your information with trusted third-party service providers who help us operate and improve everidoor."
        },
        {
            title: " Legal Requirements:"
        },
        {
            content: "We may disclose your information to comply with legal obligations, protect our rights, and ensure the safety of our users."
        },
        {
            head: "4. Your Choices:",
            title: " Account Information:"
        },
        {
            content: "You can update or delete your account information at any time through your everidoor account settings."
        },
        {
            title: " Communication:"
        },
        {
            content: "You can manage your communication preferences through your account settings or unsubscribe from marketing emails."
        },
        {
            title: "Security:"
        },
        {
            content: "We employ industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. However, no method of data transmission over the internet is completely secure."
        },
        {
            title: "Children's Privacy:"
        },
        {
            content: "everidoor is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children."
        },
        {
            title: "Changes to this Privacy Policy:"
        },
        {
            content: "We may update this Privacy Policy to reflect changes in our practices or services. We will notify you of significant changes through our services or other means."
        },
        {
            title: "Contact Us: "
        },
        {
            content: (
                <>
                    If you have questions or concerns about this Privacy Policy or your data, please contact us at contact@everidoor.com <br />
                    By using everidoor, you agree to the terms outlined in this Privacy Policy. Please review this policy periodically to stay informed about how we collect and protect your information
                </>)
        }

    ]


    return (
        <>
            <Header />
            <div className='flex   flex-wrap flex-col bg-[#f7f7f7]  lg:py-16 items-center justify-center'>

                <div className='lg:w-[1390px] p-2 flex flex-wrap lg:pl-16 md:py-16 pt-8 lg:h-auto bg-white'>
                    <div className='lg:w-fit h-[15px] pt-3 text-[12px] pl-4 lg:pl-0   text-[#CBCBCB] '>EVERIDOOR | <span className='text-[#888888]'>PRIVACY POLICY</span></div>
                    <div className='pl-5 mt-12 lg:mt-[6rem]'>
                        <div>
                            <h1 className='Ramillas lg:text-[70px] text-[40px]  text-[#340C42]'>Privacy Policy</h1>
                            <hr className='text-[#F5F5F5] w-[60%] h-[2px]' />
                        </div>
                        <div>
                            <p className='lg:text-[20px] text-[14px] text-justify pt-8 pr-4 text-[#000000] font-light'>
                                At everidoor, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our services. By accessing or using everidoor, you consent to the practices described in this policy.
                            </p>
                            {menu.map((items, index) =>
                                < ul >
                                    <li key={index} className='flex gap-3 lg:text-[20px] text-[14px] '>
                                        <span>
                                            <p className='Ramillas mt-3  font-medium'>{items.head}</p>
                                            <p className='Ramillas font-medium'>{items.title}</p>
                                        </span>
                                        <span className='text-[#000000] pr-4 text-justify font-light'>
                                            {items.content}
                                        </span>

                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

            </div >

            <Footer />

        </>
    )
}

export default Policy
