import { useState, useEffect } from "react";
import AnimatedRoutes from "./Components/AnimatedRoutes";
import Loader from "./Components/Loader"
import axios, { Axios } from "axios";
function App() {

    const [loader,setLoader]=useState(false)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(true); // Hide loader after 4 seconds
        }, 4000);

        return () => clearTimeout(timeout); // Cleanup function to clear the timeout

    }, [])

  
// /// Additional Info..................................................

// const [location, setLocation] = useState(null);
// const [userAgent, setUserAgent] = useState('');
// const [screenSize, setScreenSize] = useState({
//   width: window.innerWidth,
//   height: window.innerHeight
// });
// const [language, setLanguage] = useState(navigator.language);
// const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
// const [cookiesEnabled, setCookiesEnabled] = useState(navigator.cookieEnabled);
// const [platform, setPlatform] = useState(navigator.platform);
// const [referrer, setReferrer] = useState(document.referrer);
// const [ipAddress, setIpAddress] = useState('');
// const [address, setAddress] = useState('');
// console.log(location);

// useEffect(() => {
//   const fetchData = async () => {
//     try {
  
//       //Address
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             setLocation({
//               latitude: position.coords.latitude,
//               longitude: position.coords.longitude
//             });
//           },
//           (error) => {
//             console.error('Error getting geolocation:', error.message);
//           }
//         );
//       } else {
//         console.error('Geolocation is not supported by this browser.');
//       }

//       // Get IP address
//       const ipResponse = await fetch('https://api.ipify.org?format=json');
//       const ipData = await ipResponse.json();
//       setIpAddress(ipData.ip);

//       // Set user agent
//       setUserAgent(navigator.userAgent);

//       // Update screen size on resize
//       const handleResize = () => {
//         setScreenSize({
//           width: window.innerWidth,
//           height: window.innerHeight
//         });
//       };
//       window.addEventListener('resize', handleResize);
//       // Get user's language
//       setLanguage(navigator.language);

//       // Get user's timezone
//       setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

//       // Check if cookies are enabled
//       setCookiesEnabled(navigator.cookieEnabled);

//       // Get user's platform
//       setPlatform(navigator.platform);

//       // Get referrer
//       setReferrer(document.referrer);

//       // Get address if location is available
//       if (location) {
//         const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.latitude}&lon=${location.longitude}&zoom=18&addressdetails=1`);
//         const data = await response.json();
//         if (data.display_name) {
//           setAddress(data.display_name);
//         } else {
//           setAddress('No address found');
//         }
//       } else {
//         setAddress('No address found');
//       }

//       // Send data to server if IP address is available
//       console.log(ipAddress);
//       if (address || ipAddress ) {
//         console.log("location for",location);
//         const res = await axios.post('https://qdp72jc1-4000.inc1.devtunnels.ms/visitors_data', {
//           location: location ? location : 'Location not available',
//           userAgent: userAgent,
//           screenSize: `H- ${screenSize.height} w- ${screenSize.width}`,
//           language: language,
//           timezone: timezone,
//           cookiesEnabled: cookiesEnabled,
//           platform: platform,
//           referrer: referrer,
//           ipAddress: ipAddress,
//           address: address ? address : 'No address found',
//         });
//         console.log("Data for visitors", res);

//         // Set data in cookies
//         document.cookie = `visitorsData=${JSON.stringify({ address, timezone, language, screenSize, cookiesEnabled })}; path=/`;

//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   fetchData();
// }, [ipAddress]);

// console.log("location---------->", location);
// console.log("Address----------->", address);
// console.log('useagent--------->',userAgent);
// console.log('device width------->', screenSize);
// console.log('language----------->', language);
// console.log('timezone----------->',timezone);
// console.log('cookieEnabled------->' , cookiesEnabled);
// console.log("ipAddress------------>",ipAddress);
// console.log("platform-------------->",platform);
// console.log('referrer-------------->',referrer);
    return (
        <>
            {/* {loader?(<></>):
            (
                 <Loader/>)
            } */}
            <AnimatedRoutes />
        </>
    );
}

export default App;
